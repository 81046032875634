import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableTextInput, TextInputProps } from '@fcamna/aem-library';
import { TextInput } from '@fcamna/react-library';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { autoFormat } from '../../../utils/jsUtils';
import FieldError from '../fieldError';
import styles from './input.module.scss';

const InputComp =
  process.env.NODE_ENV === 'test'
    ? ({ label, ...otherProps }: any) => (
        <TextInput
          labelProp={{ label: label }}
          {...otherProps}
        />
      )
    : EditableTextInput;
type CustomValidationType = (val: string) => string;

export interface InputProps extends TextInputProps {
  type: string;
  name: string;
  id?: string;
  defaultValue?: string | number;
  label?: string;
  helperText?: string;
  changeHandler?: (o: Record<string, string | number | boolean>) => void;
  validate: boolean;
  required?: boolean;
  customValidation?: CustomValidationType;
  dataTestId?: string;
  disabled?: boolean;
  customErrorKeys?: string[];
  inputTypeFormat?: string;
  showBlankSpaceValidation?: boolean;
  className?: string;
  region?: string;
}

const checkValidityInput = (
  value: string,
  required: boolean,
  showBlankSpaceValidation: boolean,
  customValidation?: CustomValidationType
) => {
  if (required && !value) {
    return 'required';
  }

  if (showBlankSpaceValidation && value.length > 0 && value.trim().length === 0) {
    return 'blankspace';
  }

  if (customValidation) {
    const errorMessage = customValidation(value);
    return errorMessage;
  }

  return '';
};

const Input = ({
  type,
  name,
  label,
  id,
  defaultValue = '',
  required = false,
  changeHandler = () => null,
  validate,
  customValidation,
  dataTestId,
  disabled = false,
  customErrorKeys = [],
  inputTypeFormat,
  showBlankSpaceValidation = false,
  className,
  region = 'us',
  maxLength = 5000
}: InputProps) => {
  const [isInvalid, setIsInvalid] = useState('');
  const [val, setVal] = useState(inputTypeFormat ? autoFormat(defaultValue as string, inputTypeFormat, region) : defaultValue);

  const inputContainerRef = useRef<any>();
  const inputRef = useRef<HTMLInputElement>(null);
  const errorKeysRef = useRef<string[]>(getErrorKeys());

  function getErrorKeys() {
    // set error keys
    const arr = [];

    if (required) {
      arr.push('required');
    }

    if (showBlankSpaceValidation) {
      arr.push('blankspace');
    }

    arr.push(...customErrorKeys);

    return arr;
  }

  const triggerValidation = (iVal: string) => {
    const invalid = checkValidityInput(iVal, required, showBlankSpaceValidation, customValidation);
    setIsInvalid(invalid);
    return invalid;
  };

  const onChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    const formattedVal = inputTypeFormat ? autoFormat(val, inputTypeFormat, region) : val;
    setVal(formattedVal);
    changeHandler({ name, value: formattedVal, isInvalid: triggerValidation(formattedVal) });
  };

  useEffect(() => {
    if (validate && inputRef.current) {
      triggerValidation(inputRef.current.value);
    }
  }, [validate, inputRef.current]);

  // CXC A11Y fix
  useEffect(() => {
    const labelRef = inputContainerRef.current?.querySelector('label');
    labelRef?.setAttribute('for', `${id ?? name}`);
  }, []);

  const isCustomError = errorKeysRef.current?.length > 0 && errorKeysRef.current.includes(isInvalid);
  const isAuthorView = errorKeysRef.current?.length > 0 && AuthoringUtils.isInEditor();

  const aemProps = process.env.NODE_ENV === 'test' ? { label } : {};

  return (
    <div ref={inputContainerRef}>
      <InputComp
        inputRef={inputRef}
        type={type}
        name={name}
        id={id ?? name}
        required={required}
        onChange={onChangeHandler}
        value={val}
        inputState={!disabled && isInvalid ? 'error' : 'none'}
        className={`${styles.input} ${className}`}
        data-testid={dataTestId}
        disabled={disabled}
        aria-describedby={`error-${name}-${isInvalid}`}
        aria-invalid={Boolean(!disabled && isInvalid)}
        maxLength={maxLength}
        {...aemProps}
      />
      {isAuthorView && (
        <>
          <p>Author {name} error messages</p>
          {errorKeysRef.current.map((k) => (
            <>
              <span>{`${k}: `}</span>
              <FieldError
                key={k}
                name={`${name}-${k}`}
              />
            </>
          ))}
        </>
      )}
      {!isAuthorView && isCustomError && isInvalid && !disabled && (
        <>
          {errorKeysRef.current.map((k) =>
            isInvalid === k ? (
              <FieldError
                key={k}
                className={styles.customError}
                name={`${name}-${isInvalid}`}
              />
            ) : null
          )}
        </>
      )}
    </div>
  );
};

export default Input;
