import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useState } from 'react';
import { v4 } from 'uuid';

import ButtonWrapper from '../../atoms/button';
import fordProIcons from '../../atoms/icon';
import styles from './addMore.module.scss';

const { Add: AddSign, CancelClose: CloseRemove } = fordProIcons;

export interface IWithAddMore {
  id?: string;
  initLength: number;
  renderComp: (index: number, totalElems?: number) => React.JSX.Element;
  renderFooter?: (length: number) => React.JSX.Element;
  addCallback?: () => void;
  removeCallback?: (idx: number) => void;
  maxLimit?: number;
}

const WithAddMore = ({ id = '', initLength, renderComp, renderFooter, addCallback, removeCallback, maxLimit = 99 }: IWithAddMore) => {
  const [compList, setCompList] = useState(Array.from({ length: initLength }, () => v4()));

  const triggerOwnerFirstNameFocus = (ownerIndex: number) => {
    setTimeout(() => {
      document.getElementById(`boFirstName-${ownerIndex}`)?.focus();
    }, 250);
  };

  const getFocusIndex = (removeIndex: number, listLength: number) => {
    if (listLength === 1) {
      return 0;
    }
    return removeIndex !== listLength - 1 ? removeIndex : removeIndex - 1;
  };

  const addMore = () => {
    const newCompList = [...compList, v4()];
    setCompList(newCompList);
    addCallback?.();
    triggerOwnerFirstNameFocus(newCompList.length - 1);
  };

  const remove = (idx: number) => {
    const newCompList = [...compList];
    const value = getFocusIndex(idx, newCompList.length);
    newCompList.splice(idx, 1);
    setCompList(newCompList);
    removeCallback?.(idx);
    triggerOwnerFirstNameFocus(value);
  };

  return (
    <div id={id}>
      {AuthoringUtils.isInEditor() ? (
        <>
          {renderComp(0)}
          <ButtonWrapper
            type="button"
            name={`remove-${id}`}
            onClick={() => remove(0)}
            leftIcon={<CloseRemove size="s" />}
          />
          <ButtonWrapper
            className={styles.btn}
            type="button"
            name={`add-more-${id}`}
            onClick={addMore}
            leftIcon={
              <AddSign
                size="s"
                className={styles.btnIcon}
              />
            }
          />
        </>
      ) : (
        <>
          {compList.map((e, i) => (
            <div
              key={e}
              className={styles.compWrapper}>
              {renderComp(i, compList.length)}
              {i > 0 && (
                <div className={styles.removeButton}>
                  <ButtonWrapper
                    type="button"
                    name={`remove-${id}`}
                    onClick={() => remove(i)}
                    leftIcon={<CloseRemove size="s" />}
                  />
                </div>
              )}
            </div>
          ))}

          {renderFooter ? renderFooter(compList.length) : null}
          {compList.length < maxLimit && (
            <ButtonWrapper
              className={styles.btn}
              type="button"
              name={`add-more-${id}`}
              onClick={addMore}
              leftIcon={
                <AddSign
                  size="s"
                  className={styles.btnIcon}
                />
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default WithAddMore;
