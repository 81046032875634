import Input from '../../atoms/input';
import Text from '../../atoms/text';
import styles from './cccdInternalOnlyForm.module.scss';

const CccdInternalOnlyForm = () => {
  return (
    <div>
      <Text name="cccd-form-note" />
      <div className={styles.formWrapper}>
        <Text name="cccd-signed-by-ford" />
        <div className={styles.form}>
          <Input
            label="First Name"
            type="text"
            name="ford-firstName"
            validate={false}
            dataTestId="firstNameTest"
            disabled
          />
          <Input
            label="Last Name"
            type="text"
            name="ford-lastName"
            validate={false}
            dataTestId="lastNameTest"
            disabled
          />
          <Input
            label="Title"
            type="text"
            name="ford-title"
            validate={false}
            dataTestId="titleTest"
            disabled
          />
        </div>
      </div>
      <div className={styles.formWrapper}>
        <Text name="cccd-signed-by-guarantor" />
        <div className={styles.form}>
          <Input
            label="First Name"
            type="text"
            name="grt-firstName"
            dataTestId="grtFnTest"
            validate={false}
            disabled
          />
          <Input
            label="Last Name"
            type="text"
            name="grt-lastName"
            dataTestId="grtLnTest"
            validate={false}
            disabled
          />
          <Input
            label="Signature"
            type="text"
            name="grt-signature"
            validate={false}
            disabled
          />
          <Input
            label="Title"
            type="text"
            name="grt-title"
            validate={false}
            disabled
          />
          <Input
            label="Date"
            type="text"
            name="date"
            validate={false}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default CccdInternalOnlyForm;
