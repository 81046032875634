import { EditableButton } from '@fcamna/aem-library';

const ButtonWrapper = (props: any) => {
  return (
    <EditableButton
      {...props}
      aria-label={props?.arialLabel}
    />
  );
};

export default ButtonWrapper;
