import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { MutableRefObject, useEffect, useRef } from 'react';

import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import { getAEMPath } from '../../../utils/jsUtils';
import useValidationStatus from '../../../utils/useValidationStatus';
import RadioButtonGroup from '../../atoms/radio/radio';
import Select from '../../atoms/select';
import Text from '../../atoms/text';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import styles from './billingInfo.module.scss';
import { validateSchema } from './validation';

const AEM_PATH = getAEMPath('billingInformation');

const BillingInfo = () => {
  const RADIO_OPTIONS = ['Yes', 'No'];

  useValidationStatus({ key: 'billingInformation', validationFn: validateSchema });

  const updateFormData = useClocStore((state) => (state as IClocState).updateFormData);

  const isValid = useClocStore((state) => (state as IClocState).clocFormData.billingInformation.isValid);

  // This is one time read on init, no rerender
  const defaultValues: MutableRefObject<IDynamicObj> = useRef((useClocStore.getState() as IClocState).clocFormData.billingInformation);
  const { isVisited } = defaultValues.current;

  const formInComplete = !!(isVisited && !isValid);

  const isTotalRevenueExceedsOneMillion = useClocStore(
    (state) => (state as IClocState).clocFormData.billingInformation.isTotalRevenueExceedsOneMillion
  );

  const isInterestedInPaymentDate = useClocStore(
    (state) => (state as IClocState).clocFormData.billingInformation.isInterestedInPaymentDate
  );
  const isEnrolledInCombinedBilling = useClocStore(
    (state) => (state as IClocState).clocFormData.billingInformation.isEnrolledInCombinedBilling
  );
  const paymentDueDate = useClocStore((state) => (state as IClocState).clocFormData.billingInformation.paymentDueDate);
  const isAuthorView = AuthoringUtils.isInEditor();

  const isfirstLoad = useRef(true);
  const changeHandler = ({ name, value }: Record<string, string | number | boolean>) => {
    isfirstLoad.current = false;
    updateFormData('billingInformation', (obj: IDynamicObj) => {
      if (name === 'isEnrolledInCombinedBilling' && value === 'No') {
        delete obj['paymentDueDate'];
      }
      obj[name as string] = value;
    });
  };

  useEffect(() => {
    if (!isVisited) {
      updateFormData('billingInformation', (obj: IDynamicObj) => {
        obj.isVisited = true;
      });
    }

    fireAnalyticsLoad({ pageName: 'billingInfo' });
  }, []);

  return (
    <>
      <div className={styles.section}>
        <Text name="form-heading" />
      </div>
      <div className={styles.section}>
        {/* isTotalRevenueExceedsOneMillion */}
        <RadioButtonGroup
          name="isTotalRevenueExceedsOneMillion"
          validate={formInComplete}
          changeHandler={changeHandler}
          optionsValue={RADIO_OPTIONS}
          required
          defaultValue={isTotalRevenueExceedsOneMillion as string}
          aemPath={AEM_PATH}
        />
      </div>

      <div className={styles.section}>
        {/* isEnrolledInCombinedBilling */}
        <RadioButtonGroup
          name="isEnrolledInCombinedBilling"
          validate={formInComplete && isfirstLoad.current}
          changeHandler={changeHandler}
          optionsValue={RADIO_OPTIONS}
          required
          defaultValue={isEnrolledInCombinedBilling as string}
          aemPath={AEM_PATH}
        />
      </div>

      {isEnrolledInCombinedBilling === 'No' || isAuthorView ? (
        <div className={styles.section}>
          {/* isInterestedInPaymentDate */}
          <RadioButtonGroup
            name="isInterestedInPaymentDate"
            validate={formInComplete && isfirstLoad.current}
            changeHandler={changeHandler}
            optionsValue={RADIO_OPTIONS}
            required
            defaultValue={isInterestedInPaymentDate as string}
            aemPath={AEM_PATH}
          />
        </div>
      ) : (
        <></>
      )}
      {(isEnrolledInCombinedBilling === 'No' && isInterestedInPaymentDate === 'Yes') || isAuthorView ? (
        <div className={styles.fields}>
          {/* Payment Date */}
          <Select
            name="paymentDueDate"
            changeHandler={changeHandler}
            defaultValue={paymentDueDate as string}
            validate={formInComplete && isfirstLoad.current}
            required
            pagePath={AEM_PATH}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BillingInfo;
