import { useState } from 'react';

import useFetchHook from '../utils/useFetchHook';

export interface ISurveyAPI {
  params: { offeringsRequested?: Array<string>; permissionsRequested?: Array<string>; applicationUUId?: string; origin?: string };
}

export interface ISurveyHook {
  fireAPI: (param: ISurveyAPI) => void;
  apiState: { loading: boolean; data: any; error: any };
}

const useSurveyApi = (): ISurveyHook => {
  const baseUrl = process.env.CLOC_SERVICE_URL;

  const fireAPIInternal = useFetchHook();

  const [apiState, setApiState] = useState<ISurveyHook['apiState']>({
    loading: false,
    data: null,
    error: null
  });

  const responseHandler = async (response: Response) => {
    try {
      const data = await response.text();
      if (response.ok) {
        setApiState({
          loading: false,
          data: data,
          error: null
        });
      } else {
        throw new Error(data);
      }
    } catch (error) {
      setApiState({
        loading: false,
        data: null,
        error
      });
    }
  };

  const errorHandler = (obj: Record<string, unknown>) => {
    setApiState({
      loading: false,
      data: null,
      error: obj.error
    });

    (async () => {
      await fireAPIInternal({
        path: `${baseUrl}/v1/log`,
        options: {
          method: 'POST',
          body: JSON.stringify({
            message: 'Customer submission error: ' + obj.error
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      });
    })();
  };

  const fireAPI = ({ params }: ISurveyAPI) => {
    setApiState({
      loading: true,
      data: null,
      error: null
    });

    const path = `${baseUrl}/v1/pro/offerings`;

    const headers = {
      Accept: 'text/html',
      'Content-Type': 'application/json;charset=utf-8'
    };

    (async () => {
      await fireAPIInternal({
        path,
        options: { headers, method: 'POST', body: JSON.stringify(params) },
        resHandler: responseHandler,
        errorHandler
      });
    })();
  };

  return { fireAPI, apiState };
};

export default useSurveyApi;
