import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useEffect, useRef, useState } from 'react';

import useDealerApi, { IDealerAPI } from '../../../apis/useDealerApi';
import { IDynamicObj } from '../../../store/types';
import { debounce, isMobileMedia } from '../../../utils/jsUtils';
import FieldError from '../../atoms/fieldError';
import RadioButtonGroup from '../../atoms/radio/radio';
import SpinnerComp from '../../atoms/spinner/spinner';
import DealershipList from '../dealershipList';
import { DealerInfo } from '../dealershipList/dealershipList';
import SearchByDealership from './searchByDealership';
import SearchByLocation from './searchByLocation';
import styles from './searchDealership.module.scss';

interface SearchDealershipProps {
  changeHandler: ({ name, value }: IDynamicObj) => void;
  formInComplete: boolean;
  defaultValues?: IDynamicObj;
  hideNoDealer?: boolean;
  region?: string;
  aemPath?: string;
}

type RadioChangeValue = string | number | boolean;

const SearchDealership = ({
  changeHandler,
  formInComplete,
  defaultValues,
  hideNoDealer = false,
  aemPath = '',
  region = 'us'
}: SearchDealershipProps) => {
  const RADIO_OPTIONS = ['location', 'dealership', 'noPreference'];
  const { fireAPI, apiState } = useDealerApi();
  const [isSwitched, setIsSwitched] = useState<boolean>(false);

  const [dealerData, setDealerData] = useState(apiState);

  const debouncedFireAPI = debounce(fireAPI, 500);

  const [selectedDealer, setSelectedDealer] = useState(
    defaultValues?.selectedDealerInfo as Record<string, string | Record<string, string>> | DealerInfo
  );

  const [searchBy, setSearchBy] = useState<string>(defaultValues?.searchByLocationOrDealership as string);

  const locSearchParams = useRef<IDealerAPI>({ params: {} });

  const handleRadioChange = ({ name, value }: Record<string, RadioChangeValue>) => {
    setSearchBy(value as string);
    changeHandler({ name, value });
    setDealerData({ loading: false, data: null, error: null });
    setIsSwitched(true);
  };

  const zipHandler = ({ value, isInvalid }: Record<string, RadioChangeValue>) => {
    const postalCode = !isInvalid ? (value as string) : '';

    locSearchParams.current.params = {
      ...locSearchParams.current.params,
      postalCode: postalCode
    };

    changeHandler({ name: 'dealerZip', value: value });
  };

  const distanceHandler = ({ value }: any) => {
    locSearchParams.current.params = {
      ...locSearchParams.current.params,
      radius: value.replace(/\D/g, '')
    };
    changeHandler({ name: 'dealerDistance', value: value });
  };

  const nameHandler = ({ value }: Record<string, RadioChangeValue>) => {
    debouncedFireAPI({ params: { dealership: value } });
    changeHandler({ name: 'dealerName', value: value });
  };

  const triggerZipSearch = () => {
    fireAPI(locSearchParams.current);
  };

  const isAuthorView = AuthoringUtils.isInEditor();

  const handleDealersChange = (obj: DealerInfo[] | undefined) => {
    if (obj) {
      changeHandler({ name: 'selectedDealerInfo', value: { ...obj[0] } });
      setSelectedDealer(obj[0]);
    }
  };

  const isMobile = isMobileMedia();

  useEffect(() => {
    setDealerData(apiState);
  }, [apiState]);

  const { loading, data: dealersList, error: showNoResultError } = dealerData;
  const getSelectedValue = () => {
    if (!dealersList || showNoResultError) {
      if (selectedDealer && Object.keys(selectedDealer).length != 0) {
        return [selectedDealer];
      }
      return [];
    }
    return dealersList;
  };

  function getDealershipListComp() {
    return searchBy !== 'noPreference' ? (
      <DealershipList
        changeHandler={handleDealersChange}
        dealersList={getSelectedValue()}
        region={region}
        defaultValue={selectedDealer}
        isSelectable={!hideNoDealer}
      />
    ) : null;
  }

  return (
    <div className={styles.container}>
      <RadioButtonGroup
        name="searchByLocationOrDealership"
        defaultValue={defaultValues?.searchByLocationOrDealership as string}
        validate={false}
        changeHandler={handleRadioChange}
        horizontal={!isMobile}
        className={styles.radioGrp}
        optionsValue={RADIO_OPTIONS}
        aemPath={aemPath}
        dataTestId="searchDealerGroup"
      />
      <SearchByLocation
        region={region}
        searchBy={searchBy}
        zipHandler={zipHandler}
        triggerZipSearch={triggerZipSearch}
        distanceHandler={distanceHandler}
        aemPath={aemPath}
        isAuthorView={isAuthorView}
        defaultValues={defaultValues}
        isSwitched={isSwitched}
        formInComplete={formInComplete}
        renderDealerList={getDealershipListComp()}
      />
      <SearchByDealership
        searchBy={searchBy}
        isAuthorView={isAuthorView}
        nameHandler={nameHandler}
        formInComplete={formInComplete}
        isSwitched={isSwitched}
        defaultValues={defaultValues}
        renderDealerList={getDealershipListComp()}
      />

      {loading && (
        <div className={styles.spinner}>
          <SpinnerComp />
        </div>
      )}
      {(Boolean(showNoResultError) || isAuthorView) && (
        <div aria-live={'assertive'}>
          <FieldError
            name="noResultError"
            className={styles.error}
          />
        </div>
      )}
    </div>
  );
};

export default SearchDealership;
