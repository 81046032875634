import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableButton } from '@fcamna/aem-library';
import { InLineAlert, InLineAlertProps } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { Fragment, useEffect, useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';

import log from '../../../apis/logToApi';
import useClocStore, { FileSelection } from '../../../store';
import { IClocState } from '../../../store/types';
import { formatByteSize } from '../../../utils/jsUtils';
import FieldError from '../fieldError';
import Text from '../text/text';
import styles from './fileUpload.module.scss';
const { Delete } = fordProIcons;

const isAuthorView = AuthoringUtils.isInEditor();

export interface FileUploadProps {
  onFileAdded?: (files: FileSelection[]) => void;
  allowedFileType?: string[];
  disabled?: boolean;
  defaultFiles?: File[];
  dataTestId?: string;
  dropzone?: boolean;
  maxTotalSize?: number;
  sectionKey: string;
}

const FileUploadWrapper = (
  {
    allowedFileType,
    onFileAdded,
    disabled = false,
    maxTotalSize = 10,
    defaultFiles = [],
    dataTestId,
    dropzone = false,
    sectionKey
  }: FileUploadProps,
  args: InLineAlertProps
) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [isInfected, setIsInfected] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const businessInformation = useClocStore((state) => (state as IClocState).clocFormData.businessInformation);
  const totalFiles = useClocStore((state) => (state as IClocState).totalFiles);

  const addTotalFiles = useClocStore((state) => (state as IClocState).addTotalFiles);
  const removeTotalFiles = useClocStore((state) => (state as IClocState).removeTotalFiles);
  const getTotalFiles = useClocStore((state) => (state as IClocState).getTotalFiles);
  const isFileLoading = useClocStore((state) => (state as IClocState).isFileLoading);
  const updateIsFileLoading = useClocStore((state) => (state as IClocState).updateIsFileLoading);
  const fileUploadSection = useClocStore((state) => (state as IClocState).fileUploadSection);
  const updateFileUploadSection = useClocStore((state) => (state as IClocState).updateFileUploadSection);

  const removeFileById = (fileId: string) => {
    removeTotalFiles(fileId);
  };

  const isTotalSizeExceeded = (file: File) => {
    const getTotalSize = (docs: FileSelection[] = []) => docs.reduce((acc, doc) => acc + doc.file.size, 0);

    log.info(
      `Processing file - ${file.name} (${formatByteSize(file.size.toString())}) - ${String(businessInformation.businessName) || ''}`
    );
    const totalUploadedSize = getTotalSize(getTotalFiles());
    const totalSize = totalUploadedSize + file.size;
    return totalSize > maxTotalSize * 1024 * 1024;
  };

  const isEncrypted = async (file: File) => {
    const data = await file.text();
    return data.indexOf('/Encrypt') !== -1;
  };

  const getSectionFilesLength = (): number => {
    return totalFiles.filter((files) => files.fileSection === sectionKey).length;
  };

  const onDrop = async (acceptedFiles: File[]) => {
    updateIsFileLoading(true);
    updateFileUploadSection(sectionKey);
    setRejectedFiles([]);
    for (const file of acceptedFiles) {
      if (isTotalSizeExceeded(file)) {
        setRejectedFiles([
          {
            file: file,
            errors: [{ code: 'total-file-size-exceeded', message: '' }]
          }
        ]);
      } else if (await isEncrypted(file)) {
        setRejectedFiles([
          {
            file: file,
            errors: [{ code: 'file-encrypted', message: '' }]
          }
        ]);
      } else {
        const fileId = v4();
        addTotalFiles({ file, fileSection: sectionKey, fileId });
        setIsInfected(false);
        setIsError(false);
      }
    }

    updateIsFileLoading(false);
  };

  const acceptObject: Accept = {};
  allowedFileType?.forEach((key) => {
    acceptObject[key] = [];
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: acceptObject,
    noClick: !dropzone,
    noDrag: !dropzone,
    noKeyboard: !dropzone
  });

  useEffect(() => {
    const sectionFiles = totalFiles.filter((docFile) => docFile.fileSection === sectionKey);
    onFileAdded?.(sectionFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFiles.length]);
  return (
    <div
      data-testid={dataTestId}
      className={styles.fileUploadWrapper}>
      <div className={styles.filpUploadComp}>
        <div {...getRootProps({ className: dropzone ? styles.dropZone : {} })}>
          <input
            {...getInputProps()}
            data-testid={'file-selector-input'}
          />
          {dropzone ? (
            <Text
              id={`dropzone-label`}
              name={'dropzone-label'}
              data-testid={'dropzone-label'}
            />
          ) : (
            <EditableButton
              name={'upload-file'}
              onClick={open}
              isDisabled={disabled}
            />
          )}
        </div>
        {getSectionFilesLength() > 0 && (
          <div
            aria-live={'polite'}
            aria-relevant={'additions removals'}
            className={styles.selectedFilesWrapper}>
            {totalFiles.map((docFile) => (
              <Fragment key={docFile.fileId}>
                {docFile.fileSection === sectionKey && (
                  <div
                    key={docFile.file.name}
                    className={styles.progressBar}>
                    <Text
                      id={`file-row-label`}
                      data-testid={`file-row-label`}
                      name={`file-row-label`}
                      replacements={{ '{fileName}': docFile.file.name, '{fileSize}': formatByteSize(docFile.file.size.toString()) }}
                    />
                    <button
                      type="button"
                      aria-label="trash"
                      className={styles.iconButton}
                      onClick={() => removeFileById(docFile.fileId)}
                      disabled={isFileLoading}
                      onKeyDown={(event) => {
                        if (event.code === 'Enter' || event.code === 'Space') {
                          removeFileById(docFile.fileId);
                        }
                      }}>
                      <Delete />
                    </button>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        )}
        {isFileLoading && fileUploadSection === sectionKey && (
          <div
            aria-live={'polite'}
            aria-relevant={'additions removals'}
            className={styles.selectedFilesWrapper}>
            <div className={styles.fileLoadingItems}>
              <Text
                id={`adding-file-row`}
                name={'adding-file-row'}
              />
            </div>
          </div>
        )}
      </div>
      {fileUploadSection === sectionKey &&
        rejectedFiles.map((rejection) => {
          return rejection.errors.map((error) => {
            switch (error.code) {
              case 'total-file-size-exceeded':
                return (
                  <FieldError
                    key={`${rejection.file.name}-size-error`}
                    name="fs-max-file-size-error"
                    className={styles.maxErrMsg}
                  />
                );
              case 'file-encrypted':
                return (
                  <FieldError
                    key={`${rejection.file.name}-encrypted-error`}
                    className={styles.fieldError}
                    name="fs-file-encrypted"
                    replacement={{ '{*}': rejection.file.name }}
                  />
                );
            }
          });
        })}
      {isInfected && (
        <div className={styles.infectedFileAlert}>
          <InLineAlert
            titleProp={{
              title: 'File(s) is infected',
              headingLevel: 'h1'
            }}
            variant="error"
            body="The file you have uploaded has been infected with a virus"
            {...args}
          />
        </div>
      )}
      {isError && (
        <div className={styles.infectedFileAlert}>
          <InLineAlert
            titleProp={{
              title: 'Error',
              headingLevel: 'h1'
            }}
            variant="warning"
            body="Virus scan failed"
            {...args}
          />
        </div>
      )}
      {isAuthorView ? (
        <>
          <p>In progress file uploading text:</p>
          <Text
            id={`adding-file-row`}
            name={'adding-file-row'}
          />
          <p>File row file name text:</p>
          <Text
            id={`file-row-label`}
            name={`file-row-label`}
            replacements={{ '{fileName}': 'sample-file.pdf', '{fileSize}': '12.34MB' }}
          />
          <p>File is encrypted error text:</p>
          <FieldError
            name="fs-file-encrypted"
            replacement={{ '{*}': 'sample-file.pdf' }}
          />
          <p>Max file size exceeded text:</p>
          <FieldError
            name="fs-max-file-size-error"
            className={styles.maxErrMsg}
          />
        </>
      ) : null}
    </div>
  );
};

export default FileUploadWrapper;
