import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useEffect, useRef } from 'react';

import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import { getCurrentDate } from '../../../utils/jsUtils';
import useValidationStatus from '../../../utils/useValidationStatus';
import Accordion from '../../atoms/accordion';
import Input from '../../atoms/input';
import Text from '../../atoms/text';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import styles from './cccd.module.scss';
import CccdInternalOnlyForm from './cccdInternalOnlyForm';
import { validateSchema } from './validation';

const CCCDAgreement = () => {
  useValidationStatus({ key: 'cccdAgreement', validationFn: validateSchema });

  const updateFormData = useClocStore((state) => (state as IClocState).updateFormData);
  const businessName = useRef((useClocStore.getState() as IClocState).clocFormData.businessInformation.businessName);
  const defaultValues = useRef((useClocStore.getState() as IClocState).clocFormData.cccdAgreement);
  const { isVisited, isValid } = defaultValues.current;
  const date = useRef(getCurrentDate());

  const isAuthorView = AuthoringUtils.isInEditor();

  const formInComplete = !!(isVisited && !isValid);

  const changeHandler = ({ name, value }: Record<string, string | number | boolean>) => {
    updateFormData('cccdAgreement', (obj: IDynamicObj) => {
      obj[name as string] = value;
    });
  };

  useEffect(() => {
    if (!isVisited) {
      updateFormData('cccdAgreement', (obj: IDynamicObj) => {
        obj.isVisited = true;
      });
    }

    fireAnalyticsLoad({ pageName: 'cccd' });
  }, []);

  const nameReplacement = {
    '{businessName}': businessName.current || ''
  } as { [key: string]: string };

  return (
    <>
      {isAuthorView && (
        <div className={styles.warning}>
          <h1>**Attention AEM Authors**</h1>
          <h2>This page has legal content. Please discuss with concerned team before making changes.</h2>
        </div>
      )}
      <div className={styles.section}>
        <Text name="cccd-form-main-heading" />
      </div>
      <div className={styles.section}>
        <Text
          className={styles.cccdDescription}
          name="cccd-description"
          replacements={nameReplacement}
        />
      </div>
      <div className={styles.section}>
        <Text name="cccd-authorized-signer" />
        <div className={styles.form}>
          <Input
            label="First Name"
            type="text"
            name="firstName"
            dataTestId="cccd-firstname"
            changeHandler={changeHandler}
            required
            validate={formInComplete}
            defaultValue={defaultValues.current.firstName as string}
            showBlankSpaceValidation
          />
          <Input
            type="text"
            name="lastName"
            dataTestId="cccd-lastname"
            changeHandler={changeHandler}
            required
            validate={formInComplete}
            defaultValue={defaultValues.current.lastName as string}
            showBlankSpaceValidation
          />
          <Input
            type="text"
            name="title"
            dataTestId="cccd-title"
            changeHandler={changeHandler}
            required
            validate={formInComplete}
            defaultValue={defaultValues.current.title as string}
            showBlankSpaceValidation
          />
        </div>
        <div className={styles.date}>
          <Text name="cccd-current-date-label" />
          <span>{date.current}</span>
        </div>
        {isAuthorView ? (
          <>
            {' '}
            <Accordion name="cccd-accordion" />
            <CccdInternalOnlyForm />
          </>
        ) : (
          <Accordion
            name="cccd-accordion"
            accordionClassName={styles.accordionControl}
            accordionContent={<CccdInternalOnlyForm />}
          />
        )}
      </div>
    </>
  );
};

export default CCCDAgreement;
