import useClocStore from '../../../../store';
import { IClocState } from '../../../../store/types';
import { useModel } from '../../../../utils/aem/aemHOC';
import { getAEMPath, getAEMValue } from '../../../../utils/jsUtils';
import ReviewDetailsCard from '../../../molecules/reviewDetailsCard/reviewDetailsCard';

const ReviewBillingInfo = () => {
  const validationStatus = useClocStore((state) => (state as IClocState).validationStatus.billingInformation);
  const billingInformation = useClocStore((state) => (state as IClocState).clocFormData.billingInformation);
  //   const region = useClocStore((state) => (state as IClocState).region);
  const updateReviewStep = useClocStore((state) => (state as IClocState).updateReviewStep);
  const parsedModel = useModel({ pagePath: getAEMPath('billingInformation') }).parsedModel;

  const { isTotalRevenueExceedsOneMillion, isEnrolledInCombinedBilling, isInterestedInPaymentDate, paymentDueDate } = validationStatus;

  const transformedObj = {
    title: getAEMValue('text_form-heading', parsedModel, 'text'),
    isValid: Boolean(billingInformation.isValid),
    subSections: [
      {
        title: '',
        fields: [
          {
            title: getAEMValue('radiogroup_isTotalRevenueExceedsOneMillion', parsedModel, 'label'),
            value: typeof isTotalRevenueExceedsOneMillion === 'string' ? isTotalRevenueExceedsOneMillion : null,
            isOptional: false
          },
          {
            title: getAEMValue('radiogroup_isEnrolledInCombinedBilling', parsedModel, 'label'),
            value: typeof isEnrolledInCombinedBilling === 'string' ? isEnrolledInCombinedBilling : null,
            isOptional: false
          },
          ...(isInterestedInPaymentDate
            ? [
                {
                  title: getAEMValue('radiogroup_isInterestedInPaymentDate', parsedModel, 'label'),
                  value: typeof isInterestedInPaymentDate === 'string' ? isInterestedInPaymentDate : null,
                  isOptional: false
                }
              ]
            : []),
          ...(paymentDueDate
            ? [
                {
                  title: getAEMValue('singleselectinput_paymentDueDate', parsedModel, 'label'),
                  value: typeof paymentDueDate === 'string' ? paymentDueDate : null,
                  isOptional: false
                }
              ]
            : [])
        ]
      }
    ]
  };
  return (
    <div data-testid="billingInfo-details-card-test">
      <ReviewDetailsCard
        section={transformedObj}
        modifyHandler={() => updateReviewStep(4)}
      />
    </div>
  );
};

export default ReviewBillingInfo;
