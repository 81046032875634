import { EditableInLineAlert, EditableInLineAlertProps } from '@fcamna/aem-library';

import styles from './alert.module.scss';

const InlineAlert = (
  props: Partial<EditableInLineAlertProps> & { titleOnly?: boolean; fitWidthToContent?: boolean; className?: string }
) => {
  const { className, titleOnly = false, fitWidthToContent = false, ...others } = props;

  let classes = className ?? '';
  if (titleOnly) {
    classes += ` ${styles.titleOnly}`;
  }
  if (fitWidthToContent) {
    classes += ` ${styles.fitWidthToContent}`;
  }

  return (
    <EditableInLineAlert
      {...others}
      className={classes}
    />
  );
};

export default InlineAlert;
