import { IDynamicObj } from '../../../store/types';
import { checkFormObjectValidity, isValidString } from '../../../utils/jsUtils';

const setValidationStatus = (key: string, value: any, validationStatus: IDynamicObj) => {
  validationStatus[key] = isValidString(value) ? value : null;
};

const validateRelatedBusiness = (obj: Record<string, any>, validationStatus: IDynamicObj) => {
  const {
    hasRelatedBusiness,
    relatedBusinessName,
    relatedBusinessDescription,
    willRelatedBusinessRequireCredit,
    willRelatedBusinessSubleaseVehicles
  } = obj;

  setValidationStatus('hasRelatedBusiness', hasRelatedBusiness, validationStatus);

  if (hasRelatedBusiness === 'Yes') {
    setValidationStatus('relatedBusinessName', relatedBusinessName, validationStatus);
    setValidationStatus('relatedBusinessDescription', relatedBusinessDescription, validationStatus);
    setValidationStatus('willRelatedBusinessRequireCredit', willRelatedBusinessRequireCredit, validationStatus);
    setValidationStatus('willRelatedBusinessSubleaseVehicles', willRelatedBusinessSubleaseVehicles, validationStatus);
  }
};

const validateLoans = (obj: Record<string, any>, validationStatus: IDynamicObj) => {
  const { doesBusinessHaveLineOfCredit, loans } = obj;

  setValidationStatus('doesBusinessHaveLineOfCredit', doesBusinessHaveLineOfCredit, validationStatus);

  if (doesBusinessHaveLineOfCredit === 'Yes' && loans?.length > 0) {
    validationStatus.loans = loans.map((loan: Record<string, any>) => ({
      lender: isValidString(loan.lender) ? loan.lender : null,
      balance: isValidString(loan.balance) ? loan.balance : null,
      limit: isValidString(loan.limit) ? loan.limit : null,
      maturity: loan.maturity || null
    }));
  }
};

const validateEquipment = (obj: Record<string, any>, validationStatus: IDynamicObj) => {
  const { addEquipment, addEquipmentDescription } = obj;

  setValidationStatus('addEquipment', addEquipment, validationStatus);

  if (addEquipment === 'Yes') {
    setValidationStatus('addEquipmentDescription', addEquipmentDescription, validationStatus);
  }
};

const validateFleetInfo = (obj: Record<string, any>, region: string, validationStatus: IDynamicObj) => {
  const {
    numberOfEmployees,
    primaryRevenueSource,
    intendedUseOfVehicle,
    projectedFleetSize,
    replacementUnits,
    additionalUnits,
    annualMilesPerUnit,
    annualKilometersPerUnit,
    requestedLineAmount
  } = obj;

  setValidationStatus('numberOfEmployees', numberOfEmployees, validationStatus);
  setValidationStatus('primaryRevenueSource', primaryRevenueSource, validationStatus);
  setValidationStatus('intendedUseOfVehicle', intendedUseOfVehicle, validationStatus);

  validationStatus.projectedFleetSize = projectedFleetSize ?? null;
  validationStatus.replacementUnits = replacementUnits ?? null;
  validationStatus.additionalUnits = additionalUnits ?? null;

  if (region === 'us') {
    handleUSFleetValidation(validationStatus, annualMilesPerUnit, requestedLineAmount, replacementUnits, additionalUnits);
  } else {
    handleInternationalFleetValidation(validationStatus, annualKilometersPerUnit, requestedLineAmount);
  }
};

const handleUSFleetValidation = (
  validationStatus: IDynamicObj,
  annualMilesPerUnit: string,
  requestedLineAmount: string,
  replacementUnits: number,
  additionalUnits: number
) => {
  validationStatus.annualMilesPerUnit = annualMilesPerUnit || null;

  const requestedAmount =
    typeof requestedLineAmount === 'string' ? Number(requestedLineAmount.replace(/[^0-9.-]+/g, '')) : requestedLineAmount;

  const totalUnits = (replacementUnits ?? 0) + (additionalUnits ?? 0);
  validationStatus.requestedLineAmount = requestedAmount <= 49999 ? null : requestedLineAmount || null;

  if (totalUnits <= 0) {
    validationStatus.replacementUnits = null;
    validationStatus.additionalUnits = null;
  }
};

const handleInternationalFleetValidation = (validationStatus: IDynamicObj, annualKilometersPerUnit: any, requestedLineAmount: any) => {
  validationStatus.annualKilometersPerUnit = annualKilometersPerUnit || null;
  validationStatus.requestedLineAmount = requestedLineAmount || null;
};

const validateDealerInfo = (obj: Record<string, any>, validationStatus: IDynamicObj) => {
  const { searchByLocationOrDealership, selectedDealerInfo } = obj;

  validationStatus.selectedDealerInfo = null;

  if (['location', 'dealership'].includes(searchByLocationOrDealership) && selectedDealerInfo) {
    validationStatus.selectedDealerInfo = selectedDealerInfo.name || null;
  } else if (searchByLocationOrDealership === 'noPreference') {
    validationStatus.selectedDealerInfo = 'noPreference';
  }
};

export const validateSchema = (obj: Record<string, any>, region: string) => {
  const validationStatus: IDynamicObj = {};

  validateRelatedBusiness(obj, validationStatus);
  validateLoans(obj, validationStatus);
  validateEquipment(obj, validationStatus);
  validateFleetInfo(obj, region, validationStatus);
  validateDealerInfo(obj, validationStatus);

  return { isValid: checkFormObjectValidity(validationStatus), validationStatus };
};
