import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { Suspense } from 'react';

import AEMRoute from '../../../utils/aem/aemRoute';
import { getAEMPath } from '../../../utils/jsUtils';
import SpinnerComp from '../../atoms/spinner';
import ApplicationReview from '../../organisms/applicationReview';
import BillingInfo from '../../organisms/billingInfo/billingInfo';
import BusinessInfo from '../../organisms/businessInfo';
import CCCDAgreement from '../../organisms/cccd/cccd';
import ContactInfo from '../../organisms/contactInfo';
import FinancialStatements from '../../organisms/financialStatements/financialStatements';
import FleetInfo from '../../organisms/fleetInfo';
import SignAndSubmit from '../../organisms/signAndSubmit/signAndSubmit';
import ClocForm from '../../templates/clocForm';
import Screener from '../../templates/screener/screener';
import Survey from '../../templates/survey/survey';

const PATH_MAP: Record<string, any> = {
  screener: Screener,
  cloc: ClocForm,
  businessInformation: BusinessInfo,
  contactInformation: ContactInfo,
  fleetInformation: FleetInfo,
  billingInformation: BillingInfo,
  cccdAgreement: CCCDAgreement,
  financialStatements: FinancialStatements,
  applicationReview: ApplicationReview,
  signAndSubmit: SignAndSubmit,
  survey: Survey
};

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '24px'
      }}>
      <SpinnerComp />
    </div>
  );
};

const CompLoader = ({ path }: { path: string }) => {
  const aemPath = getAEMPath(path);
  const Comp = PATH_MAP[path];
  const isAuthorView = AuthoringUtils.isInEditor();

  return (
    <AEMRoute route={aemPath}>
      <Suspense fallback={<Loader />}>
        {Boolean(isAuthorView) && (
          <h1>Note: This is lazy loaded React component. If author options are not working, try toggle between preview mode & edit mode</h1>
        )}
        <Comp />
      </Suspense>
    </AEMRoute>
  );
};

export default CompLoader;