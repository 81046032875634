import { useState } from 'react';

import useClocStore from '../store';
import { IClocState } from '../store/types';
import useFetchHook from '../utils/useFetchHook';

export interface IDealerAPI {
  params: { postalCode?: string; radius?: number; dealership?: string };
}

export interface IDealerHook {
  fireAPI: (param: IDealerAPI) => Promise<void>;
  apiState: { loading: boolean; data: any; error: any };
}

const useDealerApi = (): IDealerHook => {
  const region = useClocStore((state) => (state as IClocState).region);
  const baseUrl = region === 'us' ? process.env.NASAPI_US_URL : process.env.NASAPI_CA_URL;
  const headers = {
    'application-id': process.env.NASAPI_API_KEY as string
  };

  const fireAPIInternal = useFetchHook();

  const [apiState, setApiState] = useState<IDealerHook['apiState']>({
    loading: false,
    data: null,
    error: null
  });

  const responseHandler = async (response: Response) => {
    try {
      const data = await response.json();
      const dealers: any = [];

      if (response.ok && data.Response.Dealer?.length) {
        data.Response.Dealer.forEach((dealer: any) => {
          const { Name, Address, PACode, SalesCode, Phone } = dealer;
          dealers.push({ name: Name, address: Address, paCode: PACode, salesCode: SalesCode, phone: Phone });
        });
        setApiState({
          loading: false,
          data: dealers,
          error: null
        });
      } else {
        throw new Error('Dealer list empty');
      }
    } catch (error) {
      setApiState({
        loading: false,
        data: null,
        error
      });
    }
  };

  const errorHandler = (obj: Record<string, unknown>) => {
    setApiState({
      loading: false,
      data: null,
      error: obj.error
    });
  };

  const fireAPI = async ({ params }: IDealerAPI): Promise<void> => {
    setApiState({
      loading: true,
      data: null,
      error: null
    });

    let path = `${baseUrl}/dealer/Dealers.json?`;
    if (params.postalCode) {
      path = `${path}postalCode=${params.postalCode}&make=Ford&radius=${params.radius ? params.radius : 100}`;
    } else {
      path = `${path}dealerName=${params.dealership}&make=Ford`;
    }
    await fireAPIInternal({
      path,
      options: { headers },
      resHandler: responseHandler,
      errorHandler
    });
  };

  return { fireAPI, apiState };
};

export default useDealerApi;
