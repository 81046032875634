import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useEffect, useRef } from 'react';

import useClocStore from '../../../store';
import { IDynamicObj } from '../../../store/types';
import { getAEMPath } from '../../../utils/jsUtils';
import useValidationStatus from '../../../utils/useValidationStatus';
import Checkbox from '../../atoms/checkbox';
import InlineAlert from '../../atoms/inlineAlert';
import Input from '../../atoms/input';
import SpinnerComp from '../../atoms/spinner/spinner';
import Text from '../../atoms/text';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import styles from './signAndSubmit.module.scss';
import { validateSchema } from './validation';

const AEM_PATH = getAEMPath('signAndSubmit');

const SignAndSubmit = () => {
  useValidationStatus({ key: 'signAndSubmit', validationFn: validateSchema });

  const isAuthorView = AuthoringUtils.isInEditor();

  const isLoading = useClocStore((state) => state.isLoading);
  const isSubmitError = useClocStore((state) => state.submitError);

  const updateFormData = useClocStore((state: { updateFormData: any }) => state.updateFormData);
  const region = useClocStore((state: { region: any }) => state.region);

  const marketingAccept = useClocStore((state: any) => state.clocFormData.signAndSubmit.marketingAccept);

  const defaultValues = useRef(useClocStore.getState().clocFormData.signAndSubmit);
  const {
    isVisited,
    isValid,
    firstName,
    lastName,
    title,
    marketingAccept: marketingAcceptInit,
    marketingComm: marketingCommInit
  } = defaultValues.current as IDynamicObj;

  const formInComplete = !!(isVisited && !isValid);

  const changeHandler = ({ name, value }: Record<string, string | number | boolean>) => {
    updateFormData('signAndSubmit', (obj: IDynamicObj) => {
      obj[name as string] = value;
    });
  };

  const marketingCheckboxHandler = ({ value, name }: IDynamicObj) => {
    const boCheckBox = value as Record<string, string | boolean>[];
    const isChecked = boCheckBox[0].checked as boolean;

    changeHandler({ name: name as string, value: isChecked });
  };

  useEffect(() => {
    if (!isVisited) {
      updateFormData('signAndSubmit', (obj: IDynamicObj) => {
        obj.isVisited = true;
      });
    }

    fireAnalyticsLoad({ pageName: 'submit' });
  }, []);

  return (
    <div className={styles.section}>
      <Text name="sas-title" />
      <Checkbox
        name="marketingAccept"
        defaultValue={{ 0: marketingAcceptInit as boolean }}
        changeHandler={marketingCheckboxHandler}
        validate={formInComplete}
        pagePath={AEM_PATH}
        className={styles.sasCheckbox}
      />
      <Text name="sas-agreement-text" />
      {region === 'ca' ? (
        <>
          <Checkbox
            name="marketingComm"
            defaultValue={{ 0: marketingCommInit as boolean }}
            changeHandler={marketingCheckboxHandler}
            validate={formInComplete}
            pagePath={AEM_PATH}
            className={styles.sasCheckbox}
          />
          <Text
            className={styles.consentText}
            name="sas-consent-text"
          />
        </>
      ) : null}

      <Text
        name="sas-authorized-section-title"
        className={styles.section}
      />
      <Input
        type="text"
        name="firstName"
        dataTestId="sas-firstName"
        changeHandler={changeHandler}
        required
        validate={formInComplete}
        defaultValue={firstName as string}
        className={styles.textInput}
        showBlankSpaceValidation
      />
      <Input
        type="text"
        name="lastName"
        dataTestId="sas-lastname"
        changeHandler={changeHandler}
        required
        validate={formInComplete}
        defaultValue={lastName as string}
        className={styles.textInput}
        showBlankSpaceValidation
      />
      <Input
        type="text"
        name="title"
        dataTestId="sas-title"
        changeHandler={changeHandler}
        required
        validate={formInComplete}
        defaultValue={title as string}
        className={styles.textInput}
        showBlankSpaceValidation
      />
      <Text
        className={styles.additionalInfo}
        name="sas-additional-info"
      />

      {isLoading && (
        <div className={styles.spinner}>
          <SpinnerComp />
        </div>
      )}

      {/* Errors */}
      {(isSubmitError || isAuthorView) && (
        <InlineAlert
          className={styles.errMsg}
          name="sas-submit-failed-error"
          data-testid="sas-submit-failed-error"
          titleOnly
        />
      )}
      {!marketingAccept && (
        <InlineAlert
          className={styles.errMsg}
          name="sas-checkbox-error"
          titleOnly
        />
      )}
    </div>
  );
};

export default SignAndSubmit;
