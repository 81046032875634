import { IDynamicObj } from '../../../store/types';
import { checkFormObjectValidity, isValidEmail, isValidNumber, isValidString, isValidUSPhone } from '../../../utils/jsUtils';

const validateBO = ({ boFirstName, boLastName, boTitle, ownership, isBusinessOwner, businessName }: Record<string, string | number>) => {
  const bo: Record<string, string | number | null> = isBusinessOwner
    ? {
        businessName: isValidString(businessName) ? businessName : null
      }
    : {
        firstName: isValidString(boFirstName) ? boFirstName : null,
        lastName: isValidString(boLastName) ? boLastName : null,
        title: isValidString(boTitle) ? boTitle : null
      };
  bo.isBusinessOwner = isBusinessOwner;
  bo.ownership = isValidNumber(ownership) ? ownership : null;
  return bo;
};

const validatePG = ({ pgFirstName, pgLastName, pgEmail, pgPhone }: Record<string, string>) => {
  const pg = {
    firstName: isValidString(pgFirstName) ? pgFirstName : null,
    lastName: isValidString(pgLastName) ? pgLastName : null,
    phoneNum: isValidUSPhone(pgPhone) ? pgPhone : null,
    email: isValidEmail(pgEmail) ? pgEmail : null
  };

  return pg;
};

const validatePrimaryContact = (obj: Record<string, any>, validationStatus: IDynamicObj) => {
  const { firstName, lastName, title, phoneNum, email } = obj;

  validationStatus.firstName = isValidString(firstName) ? firstName : null;
  validationStatus.lastName = isValidString(lastName) ? lastName : null;
  validationStatus.title = isValidString(title) ? title : null;
  validationStatus.phoneNum = isValidUSPhone(phoneNum) ? phoneNum : null;
  validationStatus.email = isValidEmail(email) ? email : null;
};

const validateField = (field: any, validationFn: (field: any) => boolean, fieldName: string, validationStatus: IDynamicObj) => {
  let validationResult;

  if (field) {
    if (validationFn(field)) {
      validationResult = field;
    } else {
      validationResult = null;
    }
  } else {
    validationResult = undefined;
  }

  validationStatus[fieldName] = validationResult;
};

const validateSecondaryContact = (obj: Record<string, any>, validationStatus: IDynamicObj, region: string) => {
  const { secondaryFirstName, secondaryLastName, secondaryTitle, secondaryPhoneNum, secondaryEmail } = obj;

  if (region === 'us') {
    validateField(secondaryFirstName, isValidString, 'secondaryFirstName', validationStatus);
    validateField(secondaryLastName, isValidString, 'secondaryLastName', validationStatus);
    validateField(secondaryTitle, isValidString, 'secondaryTitle', validationStatus);
    validateField(secondaryPhoneNum, isValidUSPhone, 'secondaryPhoneNum', validationStatus);
    validateField(secondaryEmail, isValidEmail, 'secondaryEmail', validationStatus);
  }
};

const validateBusinessOwners = (obj: Record<string, any>, validationStatus: IDynamicObj) => {
  const { businessOwners } = obj;

  if (businessOwners?.length !== 0) {
    let totalOwnership = 0;

    validationStatus.businessOwners = businessOwners.map((bo: Record<string, string | number>) => {
      const obj = validateBO(bo);
      const perc = (obj?.ownership as number) || 0;
      totalOwnership += perc;
      return obj;
    });

    validationStatus.totalOwnership = totalOwnership !== 100 ? null : totalOwnership;
  } else {
    validationStatus.businessOwners = null;
  }
};

const validatePersonalGuarantors = (obj: Record<string, any>, validationStatus: IDynamicObj, region: string) => {
  const { personalGuarantors, isPGProvided } = obj;

  if (region === 'us') {
    validationStatus.isPGProvided = isValidString(isPGProvided) ? isPGProvided : null;
    if (isPGProvided === 'Yes') {
      if (!personalGuarantors?.length) {
        validationStatus.personalGuarantors = null;
      } else {
        validationStatus.personalGuarantors = personalGuarantors.map((pg: Record<string, string>) => validatePG(pg));
      }
    }
  }
};

export const validateSchema = (obj: Record<string, any>, region: string) => {
  const validationStatus: IDynamicObj = {};

  validatePrimaryContact(obj, validationStatus);
  validateSecondaryContact(obj, validationStatus, region);
  validateBusinessOwners(obj, validationStatus);
  validatePersonalGuarantors(obj, validationStatus, region);

  return { isValid: checkFormObjectValidity(validationStatus), validationStatus };
};
