// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hIYRqlTPR4rMS0MOr8up h4{max-width:100px !important;margin:auto !important}`, "",{"version":3,"sources":["webpack://./src/components/molecules/stepTracker/stepTracker.module.scss"],"names":[],"mappings":"AAIE,yBACE,0BAAA,CACA,sBAAA","sourcesContent":["@import '../../../styles/variables.scss';\n@import '../../../styles/mixins.scss';\n\n.step {\n  h4 {\n    max-width: 100px !important;\n    margin: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": `hIYRqlTPR4rMS0MOr8up`
};
export default ___CSS_LOADER_EXPORT___;
