import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableIncrementSelector } from '@fcamna/aem-library';
import { DecrementStepper, IncrementSelector, IncrementStepper, NumberInputField } from '@fcamna/react-library';
import { useEffect, useRef, useState } from 'react';

import { useModel } from '../../../utils/aem/aemHOC';
import styles from './styles.module.scss';

interface SetUpInputProps {
  defaultValue?: number;
  name: string;
  changeHandler: (o: Record<string, string | number | boolean>) => void;
  validate: boolean;
  required?: boolean;
  aemPath?: string;
}
const checkValidityInput = (elem: HTMLInputElement, required: boolean) => {
  if (!required) {
    return false;
  }

  return !elem.value;
};

const StepUpInput = ({
  defaultValue,
  name,
  changeHandler,
  validate,
  required = false,

  aemPath = ''
}: SetUpInputProps) => {
  const [value, setValue] = useState(defaultValue ?? 0);
  const [isInvalid, setIsInvalid] = useState(false);
  const stepUpInputContainerRef = useRef<any>();
  const stepUpInputRef = useRef<HTMLInputElement>();
  const { parsedModel } = useModel({ pagePath: aemPath });

  const triggerValidation = (elem: HTMLInputElement) => {
    const invalid = checkValidityInput(elem, required);
    setIsInvalid(invalid);
  };

  function onChange(e: number): void {
    if (isNaN(e)) {
      setValue(0);
      changeHandler({ name, value: 0, isInvalid });
    } else {
      setValue(e);
      changeHandler({ name, value: e, isInvalid });
    }
    if (stepUpInputRef.current) triggerValidation(stepUpInputRef.current);
  }

  useEffect(() => {
    if (stepUpInputContainerRef.current) stepUpInputRef.current = stepUpInputContainerRef.current.querySelectorAll(`input`)[0];
    stepUpInputRef.current?.setAttribute('name', name);

    if (!defaultValue) {
      changeHandler({ name, value: 0, isInvalid });
    }
  }, []);

  useEffect(() => {
    if (validate && stepUpInputRef.current) {
      triggerValidation(stepUpInputRef.current);
    }
  }, [validate, stepUpInputRef.current]);
  if (AuthoringUtils.isInEditor()) {
    return <EditableIncrementSelector name={name} />;
  }
  if (!parsedModel?.[`incrementselector_${name}`]) {
    return null;
  }

  const { label, helperText, min, max } = parsedModel?.[`incrementselector_${name}`] || {};

  return (
    <div
      ref={stepUpInputContainerRef}
      className={styles.incrementWrapper}>
      <IncrementSelector
        labelProp={{
          label
        }}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        helperText={isInvalid ? helperText : ''}
        isInvalid={isInvalid}>
        <DecrementStepper />
        <NumberInputField data-testid={name} />
        <IncrementStepper />
      </IncrementSelector>
    </div>
  );
};

export default StepUpInput;
