import { EditableAccordion } from '@fcamna/aem-library';
import { Accordion as AccordionCmp, AccordionItem, AccordionPanel } from '@fcamna/react-library';
import { useState } from 'react';

interface AccordionProps {
  name: string;
  accordionClassName?: string;
  accordionContent?: React.ReactElement | string;
}

export default function Accordion({ name, accordionClassName = '', accordionContent = '' }: Readonly<AccordionProps>) {
  const isTestEnvironment = process.env.NODE_ENV === 'test';

  const [isOpen, setIsOpen] = useState(false);

  if (isTestEnvironment) {
    return <div data-testid={name}>{accordionContent}</div>;
  }

  return (
    <EditableAccordion
      name={name}
      renderAccordion={({ items, boxVariation }) => {
        return (
          <AccordionCmp
            className={accordionClassName}
            boxVariation={boxVariation}>
            {items?.map(({ title, subTitle }) => (
              <AccordionItem
                key={title}
                id={title}
                title={title}
                subTitle={subTitle}
                boxVariation={boxVariation}
                openAccordion={isOpen}
                setOpenAccordion={(event, id, value) => setIsOpen(value)}>
                <AccordionPanel content={accordionContent} />
              </AccordionItem>
            ))}
          </AccordionCmp>
        );
      }}
    />
  );
}
