import fordProIcons from '../../atoms/icon';
import { RadioButton } from '../../atoms/radio/radio';
import styles from './dealership.module.scss';

const { CheckmarkFilled: Success } = fordProIcons;

interface DealerDeatilsProps {
  dealer: Record<string, string | any>;
  region: string;
}
const DealerDetails = ({ dealer, region }: DealerDeatilsProps) => {
  return (
    <p className={styles.helper}>
      {dealer?.address.Street1} <br />
      {`${dealer?.address.City}, ${region === 'us' ? dealer?.address?.State : dealer?.address?.Province} ${dealer?.address.PostalCode}`}
      {dealer?.phone && (
        <>
          <br /> {dealer?.phone}
        </>
      )}
    </p>
  );
};
interface SuccessIconProps {
  selectedValue: string;
  paCode: string;
}
const SuccessIcon = ({ selectedValue, paCode }: SuccessIconProps) => {
  return selectedValue === paCode ? (
    <Success
      size="s"
      className={styles.success}
    />
  ) : null;
};

interface DealerRadioInterface {
  dealer: Record<string, string | any>;
  index: number;
  onRadioChange: (o: string | number) => void;
  selectedValue: string;
  region: string;
}

const DealerRadio = ({ dealer, index, onRadioChange, selectedValue, region }: DealerRadioInterface) => {
  return (
    <div
      className={styles.radio}
      key={dealer.salesCode}>
      <RadioButton
        label={dealer?.name as string}
        value={dealer?.paCode as string}
        onChange={onRadioChange}
        checked={selectedValue === dealer.paCode}
        dataTestId={`dealerRadio_${index}`}
        id={`dealerRadio_${index}`}
      />
      <DealerDetails
        dealer={dealer}
        region={region}
      />
      <SuccessIcon
        selectedValue={selectedValue}
        paCode={dealer?.paCode}
      />
    </div>
  );
};

export default DealerRadio;
