// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OQYZ70a96pyhREDbdblG label{max-width:fit-content}.k8pq0dhQAQ6npL53B3jm{display:none}`, "",{"version":3,"sources":["webpack://./src/components/atoms/radio/radio.module.scss"],"names":[],"mappings":"AAGE,4BACE,qBAAA,CAIJ,sBACE,YAAA","sourcesContent":["@import '../../../styles/variables.scss';\n\n.radio {\n  label {\n    max-width: fit-content;\n  }\n}\n\n.hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `OQYZ70a96pyhREDbdblG`,
	"hidden": `k8pq0dhQAQ6npL53B3jm`
};
export default ___CSS_LOADER_EXPORT___;
