import useClocStore from '../../../../store';
import { IClocState } from '../../../../store/types';
import { useModel } from '../../../../utils/aem/aemHOC';
import { getAEMPath, getAEMValue } from '../../../../utils/jsUtils';
import ReviewDetailsCard from '../../../molecules/reviewDetailsCard/reviewDetailsCard';

const ReviewCccd = () => {
  const validationStatus = useClocStore((state) => (state as IClocState).validationStatus.cccdAgreement);
  const cccdAgreement = useClocStore((state) => (state as IClocState).clocFormData.cccdAgreement);
  //   const region = useClocStore((state) => (state as IClocState).region);
  const updateReviewStep = useClocStore((state) => (state as IClocState).updateReviewStep);
  const parsedModel = useModel({ pagePath: getAEMPath('cccdAgreement') }).parsedModel;

  const { firstName, lastName, title } = validationStatus;

  const transformedObj = {
    title: getAEMValue('text_cccd-form-main-heading', parsedModel, 'text'),
    isValid: Boolean(cccdAgreement.isValid),
    subSections: [
      {
        title: '',
        fields: [
          {
            title: getAEMValue('textinput_firstName', parsedModel, 'label'),
            value: typeof firstName === 'string' ? firstName : null,
            isOptional: false
          },
          {
            title: getAEMValue('textinput_lastName', parsedModel, 'label'),
            value: typeof lastName === 'string' ? lastName : null,
            isOptional: false
          },
          {
            title: getAEMValue('textinput_title', parsedModel, 'label'),
            value: typeof title === 'string' ? title : null,
            isOptional: false
          }
        ]
      }
    ]
  };
  return (
    <div data-testid="cccd-details-card-test">
      <ReviewDetailsCard
        section={transformedObj}
        modifyHandler={() => updateReviewStep(5)}
      />
    </div>
  );
};

export default ReviewCccd;
