import { IDynamicObj } from '../../../store/types';
import { isValidPostal, isValidUSZip } from '../../../utils/jsUtils';
import ButtonWrapper from '../../atoms/button';
import fordProIcons from '../../atoms/icon';
import Input from '../../atoms/input';
import Select from '../../atoms/select';
import styles from './searchDealership.module.scss';

const { CaretRight } = fordProIcons;

const customValidations = {
  zipCode: (value: string) => {
    if (!isValidUSZip(value)) {
      return 'invalid';
    }
    return '';
  },
  postalCode: (value: string) => {
    if (!isValidPostal(value)) {
      return 'invalid';
    }
    return '';
  }
};

const SearchDealershipBtn = ({ triggerZipSearch }: { triggerZipSearch: () => void }) => (
  <ButtonWrapper
    name="dealer-search-btn"
    onClick={triggerZipSearch}
    rightIcon={
      <CaretRight
        size="s"
        className="btn-icon"
      />
    }
    className={styles.searchBtn}
  />
);
interface SearchDealershipProps {
  region: string;
  isAuthorView: boolean;
  zipHandler: (o: Record<string, string | number | boolean>) => void;
  formInComplete: boolean;
  isSwitched: boolean;
  defaultValues?: IDynamicObj;
  distanceHandler: ({ value }: any) => void;
  aemPath: string;
  triggerZipSearch: () => void;
  searchBy: string;
  renderDealerList: React.JSX.Element | null;
}

const SearchByLocation = ({
  region,
  isAuthorView,
  zipHandler,
  formInComplete,
  isSwitched,
  defaultValues,
  distanceHandler,
  aemPath,
  triggerZipSearch,
  searchBy,
  renderDealerList
}: SearchDealershipProps) => {
  if (searchBy != 'location' && !isAuthorView) {
    return null;
  }
  return (
    <div className={styles.form}>
      <div>
        <div className={styles.locationInputs}>
          {region === 'us' ? (
            <Input
              type="text"
              name="dealerZip"
              changeHandler={zipHandler}
              customValidation={customValidations.zipCode}
              validate={formInComplete && (defaultValues?.dealerZip as boolean)}
              dataTestId="zip"
              inputTypeFormat="zip"
              defaultValue={!isSwitched ? (defaultValues?.dealerZip as string) : ''}
              customErrorKeys={['invalid']}
            />
          ) : (
            <Input
              type="text"
              name="dealerPostalCode"
              changeHandler={zipHandler}
              customValidation={customValidations.postalCode}
              validate={formInComplete && (defaultValues?.dealerPostalCode as boolean)}
              defaultValue={!isSwitched ? (defaultValues?.dealerPostalCode as string) : ''}
              dataTestId="postalInput"
              customErrorKeys={['invalid']}
            />
          )}

          <div className={styles.distanceInput}>
            <Select
              name="dealerDistance"
              changeHandler={distanceHandler}
              validate={false}
              defaultValue={!isSwitched ? defaultValues?.dealerDistance : ''}
              pagePath={aemPath}
            />
          </div>
        </div>
        <div className={styles.searchBtnForDesktop}>
          <SearchDealershipBtn triggerZipSearch={triggerZipSearch} />
        </div>
        <div className={styles.dealerList}>{renderDealerList}</div>
      </div>

      <div className={styles.searchBtnForMobile}>
        <SearchDealershipBtn triggerZipSearch={triggerZipSearch} />
      </div>
    </div>
  );
};

export default SearchByLocation;
