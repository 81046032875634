import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { FinStmtsMutateFnType, IClocFormData, IClocState, IValidationStatus, MutateFnType, ValidationStatusMutateFnType } from './types';
export interface FileSelection {
  file: File;
  fileSection: string;
  fileId: string;
}

const initStore = (set: any, get: any) => ({
  locale: 'en',
  region: 'us',
  appType: 'pro',
  homePath: '',
  rootPage: '',
  triggerSubmit: false,
  isClocFormValid: { valid: false, stepsStatus: {} },
  isFileInfected: false,
  updateReviewStep: () => {},
  clocFormData: {
    businessInformation: {
      isVisited: false
    },
    contactInformation: { isVisited: false, businessOwners: [{ isBusinessOwner: false }] },
    fleetInformation: { isVisited: false },
    billingInformation: { isVisited: false },
    cccdAgreement: { isVisited: false },
    financialStatements: { isVisited: false },
    applicationReview: { isVisited: false },
    signAndSubmit: { isVisited: false }
  },
  validationStatus: {
    businessInformation: {},
    contactInformation: {},
    fleetInformation: {},
    billingInformation: {},
    cccdAgreement: {},
    financialStatements: {},
    signAndSubmit: {}
  },
  financialStatementsDocs: {
    mostRecentDocs: undefined,
    secondRecentDocs: undefined,
    thirdRecentDocs: undefined,
    canadaDocs: undefined
  },
  totalFiles: [],
  cookies: null,
  submitError: false,
  isFileLoading: false,
  fileUploadSection: '',
  updateFileUploadSection: (section: string) => set({ fileUploadSection: section }),
  updateIsFileLoading: (loading: boolean) => set({ isFileLoading: loading }),
  addTotalFiles: (newFile: FileSelection) =>
    set((state: IClocState) => ({
      totalFiles: [...state.totalFiles, newFile]
    })),
  removeTotalFiles: (fileId: string) =>
    set((state: IClocState) => ({
      totalFiles: state.totalFiles.filter((file: FileSelection) => file.fileId !== fileId)
    })),
  getTotalFiles: () => get().totalFiles,
  updateSubmitError: (isError: boolean) => set({ submitError: isError }),
  isLoading: false,
  updateIsLoading: (loading: boolean) => set({ isLoading: loading }),
  updateFileInfected: (infected: any) => set({ isFileInfected: infected }),
  updateCookies: (c: any) => set({ cookies: c }),
  updateRootPage: (page: string) => set({ rootPage: page }),
  updateFormData: (key: keyof IClocFormData, fn: MutateFnType) =>
    set((state: IClocState) => {
      const { clocFormData } = state;
      fn(clocFormData[key]);
    }),
  updateFinancialStatementsDocs: (fn: FinStmtsMutateFnType) =>
    set((state: IClocState) => {
      const { financialStatementsDocs } = state;
      fn(financialStatementsDocs);
    }),
  updateClocFormValidity: (obj: boolean) => set({ isClocFormValid: obj }),
  updateValidationStatus: (key: keyof IValidationStatus, fn: ValidationStatusMutateFnType) =>
    set((state: IClocState) => {
      const { validationStatus } = state;
      fn(validationStatus);
    }),
  restoreClocState: (data: Record<string, any>) =>
    set((state: any) => {
      Object.keys(data).forEach((k: string) => {
        state[k] = data[k];
      });
    }),
  updateGlobalConfig: (data: Record<string, any>) =>
    set((state: any) => {
      Object.keys(data).forEach((k: string) => {
        state[k] = data[k];
      });
    }),
  updateValidationStateOnReload: () =>
    set((state: any) => {
      // Doc upload is restored on page reload.
      state.clocFormData.financialStatements.isValid = false;
      state.clocFormData.applicationReview.isValid = false;
      state.clocFormData.signAndSubmit = {
        ...state.clocFormData.signAndSubmit,
        marketingAccept: false,
        marketingComm: false,
        isValid: false
      };
      state.validationStatus.signAndSubmit.marketingAccept = false;
      state.validationStatus.signAndSubmit.marketingComm = false;
      const prevStepsStatus = state.isClocFormValid.stepsStatus || {};
      state.isClocFormValid = {
        valid: false,
        stepsStatus: { ...prevStepsStatus, signAndSubmit: false, financialStatements: false, applicationReview: false }
      };
    })
});

const useClocStore = create(subscribeWithSelector(immer(initStore)));

export default useClocStore;
