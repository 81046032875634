import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useEffect } from 'react';

import useClocStore from '../../../store';
import Btn, { default as ButtonWrapper } from '../../atoms/button';
import fordProIcons from '../../atoms/icon';
import InlineAlert from '../../atoms/inlineAlert';
import Text from '../../atoms/text';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import styles from './applicationReview.module.scss';
import ReviewBillingInfo from './reviewFormSections/reviewBillingInfo';
import ReviewBusinessInfo from './reviewFormSections/reviewBusinessInfo';
import ReviewCccd from './reviewFormSections/reviewCccd';
import ReviewContactInfo from './reviewFormSections/reviewContactInfo';
import ReviewFinancialStatements from './reviewFormSections/reviewFinancialStatements';
import ReviewFleetInfo from './reviewFormSections/reviewFleetInfo';

const { CaretRight } = fordProIcons;

const GlobalAlert = ({ isClocFormValid, isAuthorView }: { isClocFormValid: boolean; isAuthorView: boolean }) => {
  return (
    <>
      {!isClocFormValid && !isAuthorView ? (
        <div className={styles.globalAlert}>
          <InlineAlert
            name="review-global-missing-alert"
            titleOnly
          />
        </div>
      ) : null}
    </>
  );
};

const ApplicationReview = () => {
  const isAuthorView = AuthoringUtils.isInEditor();
  const region = useClocStore((state) => state.region);
  const { valid: isClocFormValid } = useClocStore((state: any) => state.isClocFormValid);

  useEffect(() => {
    fireAnalyticsLoad({ pageName: 'review' });
  }, []);

  return (
    <>
      {isAuthorView ? (
        <h1>Review page sections will use the same content from previous steps. Author view renders only authorable fields.</h1>
      ) : null}
      <div className={styles.section}>
        <Text name="review-main-heading" />
        <Text name="review-description" />
      </div>
      <div className={styles.mainSection}>
        <div>
          <GlobalAlert
            isAuthorView={isAuthorView}
            isClocFormValid={isClocFormValid}
          />
        </div>

        {isAuthorView ? (
          <>
            <div className={styles.authorableFieldWrapper}>
              <h3>Global error alert : </h3>
              <InlineAlert
                name="review-global-missing-alert"
                titleOnly
              />
            </div>

            <div className={styles.authorableFieldWrapper}>
              <h3>Specific field error alert : </h3>
              <ButtonWrapper name="review-missing-alert" />
            </div>

            <div className={styles.authorableFieldWrapper}>
              <h3>Doing Business as Validation atleast two characters: </h3>
              <InlineAlert
                name="doingBusinessAs-error-alert"
                titleOnly
              />
            </div>

            <div className={styles.authorableFieldWrapper}>
              <h3>Business owners total ownership error : </h3>
              <InlineAlert
                name="total-ownership-error-alert"
                titleOnly
              />
            </div>
            <br />
            <div>
              <h2>Financial Statements Review Section </h2>
              <div className={styles.authorableFieldWrapper}>
                <h3>Financial statement missing alert :</h3>
                <InlineAlert
                  name="financial-statement-missing-alert"
                  titleOnly
                />
              </div>
              {region === 'us' ? (
                <>
                  <div className={styles.authorableFieldWrapper}>
                    <h3>Specific Document Missing error :</h3>
                    <Text name="financial-statement-no-document" />
                  </div>
                  <div className={styles.authorableFieldWrapper}>
                    <h3>Documents modify note/sub-title :</h3>
                    <Text name="financial-document-modify-note" />
                  </div>
                  <div className={styles.authorableFieldWrapper}>
                    <h3>Fleet Information - Requested Line Amount Validation error alert:</h3>
                    <InlineAlert
                      name="requested-line-amount-validate-error-alert"
                      titleOnly
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.authorableFieldWrapper}>
                    <h3>Documents uploaded sucess message:</h3>
                    <Text name="ca-financial-statement-document-uploaded-title" />
                  </div>
                  <div className={styles.authorableFieldWrapper}>
                    <h3>Documents uploaded success sub-message:</h3>
                    <Text name="ca-financial-statement-document-uploaded-subtitle" />
                  </div>
                  <div className={styles.authorableFieldWrapper}>
                    <h3>Documents missing message:</h3>
                    <Text name="ca-financial-statement-document-missing-notes" />
                  </div>
                </>
              )}
            </div>

            <div className={styles.authorableFieldWrapper}>
              <h3>Modify form data button</h3>
              <Btn
                name={`review-modify`}
                rightIcon={
                  <CaretRight
                    size="s"
                    className="btn-icon"
                  />
                }
              />
              <Btn
                name={`review-modify-outline`}
                rightIcon={
                  <CaretRight
                    size="s"
                    className="btn-icon"
                  />
                }
              />
            </div>
          </>
        ) : (
          <div
            className={styles.reviewSection}
            data-testid="application-review-test">
            <div>
              <ReviewBusinessInfo />
            </div>
            <div>
              <ReviewContactInfo />
            </div>
            <div>
              <ReviewFleetInfo />
            </div>
            {region === 'us' ? (
              <>
                <div>
                  <ReviewBillingInfo />
                </div>
                <div>
                  <ReviewCccd />
                </div>
              </>
            ) : null}
            <div>
              <ReviewFinancialStatements />
            </div>
          </div>
        )}
      </div>
      <GlobalAlert
        isAuthorView={isAuthorView}
        isClocFormValid={isClocFormValid}
      />
    </>
  );
};

export default ApplicationReview;
