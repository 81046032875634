import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableTextArea } from '@fcamna/aem-library';
import { TextArea } from '@fcamna/react-library';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { useModel } from '../../../utils/aem/aemHOC';
import FieldError from '../fieldError';
import styles from './textArea.module.scss';

type CustomValidationType = (val: string) => string;

export interface TextBoxProps {
  name: string;
  defaultValue: string | number | boolean;
  changeHandler: (o: Record<string, string | number | boolean>) => void;
  validate: boolean;
  required?: boolean;
  customValidation?: CustomValidationType;
  dataTestId?: string;
  customErrorKeys?: string[];
  showBlankSpaceValidation?: boolean;
  aemPath?: string;
}

const checkValidityInput = (
  elem: HTMLTextAreaElement,
  required: boolean,
  showBlankSpaceValidation: boolean,
  customValidation?: CustomValidationType
) => {
  if (required && !elem.value) {
    return 'required';
  }
  if (showBlankSpaceValidation && elem.value.length > 0 && elem.value.trim().length === 0) {
    return 'blankspace';
  }

  if (customValidation) {
    const errorMessage = customValidation(elem.value);
    return errorMessage;
  }

  return '';
};

const TextBox = ({
  name,
  defaultValue,
  changeHandler,
  validate,
  required = false,
  customValidation,
  dataTestId,
  customErrorKeys = [],
  showBlankSpaceValidation = false,
  aemPath = ''
}: TextBoxProps) => {
  const [isInvalid, setIsInvalid] = useState('');

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { parsedModel } = useModel({ pagePath: aemPath });
  const triggerValidation = (elem: HTMLTextAreaElement) => {
    const invalid = checkValidityInput(elem, required, showBlankSpaceValidation, customValidation);
    setIsInvalid(invalid);
  };

  const onChangeHandler = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    const val = e.currentTarget.value;
    triggerValidation(e.currentTarget);
    changeHandler({ name, value: val, isInvalid });
  };

  useEffect(() => {
    if (validate && textAreaRef.current && parsedModel?.[`textarea_${name}`]) {
      triggerValidation(textAreaRef.current);
    }
  }, [validate, textAreaRef.current, parsedModel?.[`textarea_${name}`]]);

  const isCustomError = customErrorKeys?.length > 0;
  const isAuthorView = isCustomError && AuthoringUtils.isInEditor();
  if (AuthoringUtils.isInEditor()) {
    return (
      <>
        {isAuthorView && (
          <>
            <EditableTextArea name={name} />
            <p>Author {name} error messages</p>
            {customErrorKeys.map((k) => (
              <FieldError
                key={k}
                name={`${name}-${k}`}
              />
            ))}
          </>
        )}
      </>
    );
  }
  if (!parsedModel?.[`textarea_${name}`]) {
    return null;
  }

  const { label, helperText, maxCharacters, isIncremental } = parsedModel?.[`textarea_${name}`] || {};
  return (
    <div>
      <TextArea
        labelProp={{ label: label }}
        helperText={helperText}
        defaultValue={defaultValue as string}
        isInvalid={Boolean(isInvalid)}
        data-testid={dataTestId}
        onChange={onChangeHandler}
        textareaRef={textAreaRef}
        className={styles.textArea}
        maxCharacters={maxCharacters}
        isIncremental={isIncremental}
        name={name}
        aria-describedby={`error-${name}-${isInvalid}`}
      />
      {isCustomError && isInvalid && (
        <>
          {customErrorKeys.map((k) =>
            isInvalid === k ? (
              <FieldError
                key={k}
                className={`${styles.customError} ${!helperText ? styles.nohelperError : ''}`}
                name={`${name}-${isInvalid}`}
              />
            ) : null
          )}
        </>
      )}
    </div>
  );
};

export default TextBox;
