import { IDynamicObj } from '../../../store/types';
import { checkFormObjectValidity, isValidString } from '../../../utils/jsUtils';

export const validateSchema = (obj: Record<string, any>) => {
  const { isTotalRevenueExceedsOneMillion, isEnrolledInCombinedBilling, isInterestedInPaymentDate, paymentDueDate } = obj;

  const validationStatus: IDynamicObj = {};
  validationStatus.isTotalRevenueExceedsOneMillion = isValidString(isTotalRevenueExceedsOneMillion)
    ? isTotalRevenueExceedsOneMillion
    : null;
  validationStatus.isEnrolledInCombinedBilling = isEnrolledInCombinedBilling || null;
  if (isEnrolledInCombinedBilling === 'No') {
    validationStatus.isInterestedInPaymentDate = isValidString(isInterestedInPaymentDate) ? isInterestedInPaymentDate : null;
  }
  if (isInterestedInPaymentDate === 'Yes') {
    validationStatus.paymentDueDate = isValidString(paymentDueDate) ? paymentDueDate : null;
  }

  return { isValid: checkFormObjectValidity(validationStatus), validationStatus };
};
