import { IDynamicObj, IFinancialStatementsDocs } from '../../../store/types';
import { checkFormObjectValidity, isValidString, validateFileSize } from '../../../utils/jsUtils';
import { maxFileSize, RADIO_OPTIONS } from './financialStatements';

const validateUSDocs = (obj: Record<string, any>, financialDocs: IFinancialStatementsDocs | undefined, validationStatus: IDynamicObj) => {
  const { mostRecentDocName, secondRecentDocName, thirdRecentDocName } = obj;
  const isDocNotAvailable = RADIO_OPTIONS[3]; // this is no document to upload option

  validationStatus.mostRecentDocName = null;
  validationStatus.secondRecentDocName = undefined;
  validationStatus.thirdRecentDocName = undefined;

  if (mostRecentDocName !== isDocNotAvailable && financialDocs?.mostRecentDocs?.length) {
    validationStatus.mostRecentDocName =
      isValidString(mostRecentDocName) && validateFileSize(financialDocs.mostRecentDocs, maxFileSize) ? mostRecentDocName : null;
  }
  if (secondRecentDocName !== isDocNotAvailable && financialDocs?.secondRecentDocs?.length) {
    validationStatus.secondRecentDocName =
      isValidString(secondRecentDocName) && validateFileSize(financialDocs.secondRecentDocs, maxFileSize) ? secondRecentDocName : null;
  }
  if (thirdRecentDocName !== isDocNotAvailable && financialDocs?.thirdRecentDocs?.length) {
    validationStatus.thirdRecentDocName =
      isValidString(thirdRecentDocName) && validateFileSize(financialDocs.thirdRecentDocs, maxFileSize) ? thirdRecentDocName : null;
  }
};

const validateCanadaDocs = (financialDocs: IFinancialStatementsDocs | undefined, validationStatus: IDynamicObj) => {
  validationStatus.canadaDocs =
    financialDocs?.canadaDocs?.length && validateFileSize(financialDocs?.canadaDocs, maxFileSize) ? financialDocs?.canadaDocs : null;
};

export const validateSchema = (obj: Record<string, any>, region: string, financialDocs: IFinancialStatementsDocs | undefined) => {
  const validationStatus: IDynamicObj = {};

  if (region === 'us') {
    validateUSDocs(obj, financialDocs, validationStatus);
  } else {
    validateCanadaDocs(financialDocs, validationStatus);
  }

  return { isValid: checkFormObjectValidity(validationStatus), validationStatus };
};
