import { IDynamicObj } from '../../../store/types';
import Input from '../../atoms/input';
import styles from './searchDealership.module.scss';

interface SearchByDealership {
  searchBy: string;
  defaultValues?: IDynamicObj;
  nameHandler: ({ value }: Record<string, string | number | boolean>) => void;
  formInComplete: boolean;
  isSwitched: boolean;
  isAuthorView: boolean;
  renderDealerList: React.JSX.Element | null;
}

const SearchByDealership = ({
  searchBy,
  nameHandler,
  formInComplete,
  isSwitched,
  defaultValues,
  isAuthorView,
  renderDealerList
}: SearchByDealership) => {
  if (searchBy != 'dealership' && !isAuthorView) {
    return null;
  }
  return (
    <div className={styles.dealerNameInput}>
      <Input
        type="text"
        name="dealerName"
        changeHandler={nameHandler}
        validate={formInComplete}
        defaultValue={!isSwitched ? (defaultValues?.dealerName as string) : ''}
        dataTestId="searchByNameInput"
      />
      <div className={styles.dealerList}>{renderDealerList}</div>
    </div>
  );
};

export default SearchByDealership;
