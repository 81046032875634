import { IDynamicObj } from '../../../store/types';
import { checkFormObjectValidity, isValidString } from '../../../utils/jsUtils';

export const validateSchema = (obj: Record<string, any>) => {
  const { firstName, lastName, title, marketingAccept } = obj;
  const validationStatus: IDynamicObj = {};

  validationStatus.firstName = isValidString(firstName) ? firstName : null;
  validationStatus.lastName = isValidString(lastName) ? lastName : null;
  validationStatus.title = isValidString(title) ? title : null;
  validationStatus.marketingAccept = marketingAccept || null;

  return { isValid: checkFormObjectValidity(validationStatus), validationStatus };
};
