import { useState } from 'react';

import { IDynamicObj } from '../../../store/types';
import DealerRadio from './DealerRadio';
import styles from './dealership.module.scss';
export interface DealerInfo {
  name: string;
  address: {
    Street1: string;
    Street2?: string;
    Street3?: string;
    State?: string;
    Province?: string;
    PostalCode: string;
    Country: string;
    City: string;
  };
  salesCode: string;
  paCode: string;
  phone?: string;
}

interface DealershipListProps {
  changeHandler: (obj: DealerInfo[] | undefined) => void;
  dealersList: DealerInfo[];
  region?: string;
  defaultValue?: DealerInfo | IDynamicObj;
  isSelectable?: boolean;
}

const DealershipList = ({ changeHandler, dealersList, defaultValue, region = 'us', isSelectable = true }: DealershipListProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue?.paCode);

  const onRadioChange = (option: string | number) => {
    setSelectedValue(option as string);
    const dealer = dealersList?.filter((dealer) => dealer.paCode === option);
    changeHandler(dealer);
  };

  return (
    <>
      {dealersList?.length > 0 ? (
        <div className={styles.container}>
          {dealersList?.map((dealer, index) => {
            return (
              <div
                key={`${dealer.paCode}-dealer-${dealer.name}`}
                className={styles.dealerOption}>
                {isSelectable ? (
                  <DealerRadio
                    dealer={dealer}
                    index={index}
                    onRadioChange={onRadioChange}
                    selectedValue={selectedValue as string}
                    region={region}
                  />
                ) : (
                  <span className={styles.name}>
                    {dealer?.name} <br />
                    <span className={styles.details}>
                      {dealer?.address.Street1} <br />
                      {`${dealer?.address.City}, ${region === 'us' ? dealer?.address?.State : dealer?.address?.Province} ${dealer?.address
                        .PostalCode}`}
                      {dealer?.phone ? (
                        <>
                          <br /> {dealer?.phone}
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                  </span>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DealershipList;
