import useClocStore from '../../../../store';
import { IClocState } from '../../../../store/types';
import { useModel } from '../../../../utils/aem/aemHOC';
import { getAEMPath, getAEMValue, getDateFromObj } from '../../../../utils/jsUtils';
import InlineAlert from '../../../atoms/inlineAlert';
import ReviewDetailsCard from '../../../molecules/reviewDetailsCard/reviewDetailsCard';

const ReviewFleetInfo = () => {
  const validationStatus = useClocStore((state) => (state as IClocState).validationStatus.fleetInformation);
  const fleetInformation = useClocStore((state) => (state as IClocState).clocFormData.fleetInformation);
  const region = useClocStore((state) => (state as IClocState).region);
  const locale = useClocStore((state) => (state as IClocState).locale);
  const updateReviewStep = useClocStore((state) => (state as IClocState).updateReviewStep);
  const parsedModel = useModel({ pagePath: getAEMPath('fleetInformation') }).parsedModel;

  const {
    hasRelatedBusiness,
    doesBusinessHaveLineOfCredit,
    addEquipment,
    addEquipmentDescription,
    numberOfEmployees,
    primaryRevenueSource,
    intendedUseOfVehicle,
    projectedFleetSize,
    replacementUnits,
    additionalUnits,
    annualMilesPerUnit,
    annualKilometersPerUnit,
    requestedLineAmount,
    relatedBusinessName,
    relatedBusinessDescription,
    willRelatedBusinessRequireCredit,
    willRelatedBusinessSubleaseVehicles,
    loans,
    selectedDealerInfo
  } = validationStatus;

  function parseYesOrNoByLocale(value: string) {
    if (locale === 'fr') {
      return value === 'Yes' ? 'Oui' : 'Non';
    }
    return value;
  }

  function getDealerInfo() {
    const noPreferenceLabel = parsedModel?.['radiogroup_searchByLocationOrDealership']?.items[2]?.label || '';
    return selectedDealerInfo === 'noPreference' ? noPreferenceLabel : selectedDealerInfo;
  }

  function getLenderHeading(index: number) {
    const headingFromAEM = getAEMValue('text_lender-heading', parsedModel, 'text').replace('{num}', index + 1);
    return <span>{headingFromAEM}</span>;
  }

  function getRelatedBusiness() {
    if (hasRelatedBusiness !== 'Yes') return [];
    return [
      {
        title: getAEMValue('textarea_relatedBusinessName', parsedModel, 'label'),
        value: typeof relatedBusinessName === 'string' ? relatedBusinessName : null,
        isOptional: false
      },
      {
        title: getAEMValue('textarea_relatedBusinessDescription', parsedModel, 'label'),
        value: typeof relatedBusinessDescription === 'string' ? relatedBusinessDescription : null,
        isOptional: false
      },
      {
        title: getAEMValue('radiogroup_willRelatedBusinessRequireCredit', parsedModel, 'label'),
        value: typeof willRelatedBusinessRequireCredit === 'string' ? parseYesOrNoByLocale(willRelatedBusinessRequireCredit) : null,
        isOptional: false
      },
      {
        title: getAEMValue('radiogroup_willRelatedBusinessSubleaseVehicles', parsedModel, 'label'),
        value: typeof willRelatedBusinessSubleaseVehicles === 'string' ? parseYesOrNoByLocale(willRelatedBusinessSubleaseVehicles) : null,
        isOptional: false
      }
    ];
  }

  function getAddedEquipment() {
    if (addEquipment !== 'Yes') return [];
    return [
      {
        title: getAEMValue('textarea_addEquipmentDescription', parsedModel, 'label'),
        value: typeof addEquipmentDescription === 'string' ? addEquipmentDescription : null,
        isOptional: false
      }
    ];
  }

  function getLenders(loans: Array<any>) {
    return loans.map((loan, index) => ({
      title: getLenderHeading(index),
      fields: [
        {
          title: getAEMValue('textinput_lender', parsedModel, 'label'),
          value: typeof loan.lender === 'string' ? loan.lender : null,
          isOptional: false
        },
        {
          title: getAEMValue('textinput_limit', parsedModel, 'label'),
          value: typeof loan.limit === 'string' ? loan.limit : null,
          isOptional: false
        },
        {
          title: getAEMValue('textinput_balance', parsedModel, 'label'),
          value: typeof loan.balance === 'string' ? loan.balance : null,
          isOptional: false
        },
        {
          title: getAEMValue('datepicker_maturity', parsedModel, 'inputLabel'),
          value: getDateFromObj(loan.maturity),
          isOptional: false
        }
      ]
    }));
  }

  function getPrimaryFields() {
    return [
      {
        title: getAEMValue('radiogroup_hasRelatedBusiness', parsedModel, 'label'),
        value: typeof hasRelatedBusiness === 'string' ? parseYesOrNoByLocale(hasRelatedBusiness) : null,
        isOptional: false
      },
      ...getRelatedBusiness(),
      {
        title: getAEMValue('textinput_numberOfEmployees', parsedModel, 'label'),
        value: typeof numberOfEmployees === 'string' ? numberOfEmployees : null,
        isOptional: false
      },
      {
        title: getAEMValue('textinput_primaryRevenueSource', parsedModel, 'label'),
        value: typeof primaryRevenueSource === 'string' ? primaryRevenueSource : null,
        isOptional: false
      },
      {
        title: getAEMValue('textarea_intendedUseOfVehicle', parsedModel, 'label'),
        value: typeof intendedUseOfVehicle === 'string' ? intendedUseOfVehicle : null,
        isOptional: false
      },
      {
        title: getAEMValue('radiogroup_addEquipment', parsedModel, 'label'),
        value: typeof addEquipment === 'string' ? parseYesOrNoByLocale(addEquipment) : null,
        isOptional: false
      },
      ...getAddedEquipment(),
      {
        title: getAEMValue('radiogroup_doesBusinessHaveLineOfCredit', parsedModel, 'label'),
        value: typeof doesBusinessHaveLineOfCredit === 'string' ? parseYesOrNoByLocale(doesBusinessHaveLineOfCredit) : null,
        isOptional: false
      }
    ];
  }

  function getProjectedFleetFields() {
    return [
      {
        title: getAEMValue('textinput_projectedFleetSize', parsedModel, 'label'),
        value: typeof projectedFleetSize === 'string' ? projectedFleetSize : null,
        isOptional: false
      },
      {
        title: getAEMValue('incrementselector_replacementUnits', parsedModel, 'label'),
        value: typeof replacementUnits === 'number' ? String(replacementUnits) : null,
        isOptional: false
      },
      {
        title: getAEMValue('incrementselector_additionalUnits', parsedModel, 'label'),
        value: typeof additionalUnits === 'number' ? String(additionalUnits) : null,
        isOptional: false
      },
      ...(region === 'us'
        ? [
            {
              title: getAEMValue('textinput_annualMilesPerUnit', parsedModel, 'label'),
              value: typeof annualMilesPerUnit === 'string' ? annualMilesPerUnit : null,
              isOptional: false
            }
          ]
        : [
            {
              title: getAEMValue('textinput_annualKilometersPerUnit', parsedModel, 'label'),
              value: typeof annualKilometersPerUnit === 'string' ? annualKilometersPerUnit : null,
              isOptional: false
            }
          ]),
      {
        title: getAEMValue('textinput_requestedLineAmount', parsedModel, 'label'),
        value: typeof requestedLineAmount === 'string' ? requestedLineAmount : null,
        isOptional: false
      },
      {
        title: getAEMValue('text_dealers-heading', parsedModel, 'text'),
        value: selectedDealerInfo ? getDealerInfo() : null,
        isOptional: false
      }
    ];
  }

  function getRequestedLineAmountSubSection() {
    if (region === 'us' && !requestedLineAmount) {
      return [
        {
          title: (
            <InlineAlert
              name="requested-line-amount-validate-error-alert"
              titleOnly
            />
          ),
          fields: []
        }
      ];
    }
    return [];
  }

  const transformedObj = {
    title: getAEMValue('text_fleet-information', parsedModel, 'text'),
    isValid: Boolean(fleetInformation.isValid),
    subSections: [
      {
        title: '',
        fields: getPrimaryFields()
      },
      ...getLenders((loans as Array<any>) ?? []),
      {
        title: getAEMValue('text_projected-fleet', parsedModel, 'text'),
        fields: getProjectedFleetFields()
      },
      ...getRequestedLineAmountSubSection()
    ]
  };

  return (
    <div data-testid="fleet-info-details-card-test">
      <ReviewDetailsCard
        section={transformedObj}
        modifyHandler={() => updateReviewStep(3)}
      />
    </div>
  );
};

export default ReviewFleetInfo;
