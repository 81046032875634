import useClocStore from '../../../../store';
import { IClocState } from '../../../../store/types';
import { useModel } from '../../../../utils/aem/aemHOC';
import { getAEMPath, getAEMValue } from '../../../../utils/jsUtils';
import InlineAlert from '../../../atoms/inlineAlert';
import ReviewDetailsCard from '../../../molecules/reviewDetailsCard/reviewDetailsCard';

const ReviewContactInfo = () => {
  const validationStatus = useClocStore((state) => (state as IClocState).validationStatus.contactInformation);
  const contactInformation = useClocStore((state) => (state as IClocState).clocFormData.contactInformation);
  const region = useClocStore((state) => (state as IClocState).region);
  const updateReviewStep = useClocStore((state) => (state as IClocState).updateReviewStep);
  const parsedModel = useModel({ pagePath: getAEMPath('contactInformation') }).parsedModel;

  const {
    firstName,
    lastName,
    title,
    phoneNum,
    email,
    secondaryFirstName,
    secondaryLastName,
    secondaryTitle,
    secondaryPhoneNum,
    secondaryEmail,
    businessOwners,
    personalGuarantors,
    isPGProvided,
    totalOwnership
  } = validationStatus;

  const createField = (labelKey: string, value: any, isOptional: boolean) => ({
    title: getAEMValue(labelKey, parsedModel, 'label'),
    value: typeof value === 'string' ? value : null,
    isOptional
  });

  const getBusinessOwners = (owners: Array<any>): Array<any> => {
    if (owners.length === 0) {
      owners = [
        {
          firstName: null,
          isBusinessOwner: false,
          lastName: null,
          ownership: null,
          title: null
        }
      ];
    }

    return owners.map((bo, index) => {
      const fields = bo.isBusinessOwner
        ? [createField('textinput_businessName', bo.businessName, false)]
        : [
            createField('textinput_boFirstName', bo.firstName, false),
            createField('textinput_boLastName', bo.lastName, false),
            createField('textinput_boTitle', bo.title, false)
          ];

      fields.push(createField('textinput_ownership', `${bo.ownership}%`, false));
      return {
        title: getBOHeading(index),
        fields
      };
    });
  };

  function getBOHeading(index: number) {
    return (
      <>
        <span>{getAEMValue('text_1-business-owner-heading', parsedModel, 'text')}</span>
        <span>{' - '}</span>
        <span
          dangerouslySetInnerHTML={{ __html: getAEMValue('text_bo-add-heading', parsedModel, 'text').replace('{num}', index + 1) }}></span>
      </>
    );
  }

  function getPGHeading(index: number) {
    return <span>{getAEMValue('text_pg-heading', parsedModel, 'text').replace('{num}', index + 1)}</span>;
  }

  function getPersonalGuarantors() {
    return {
      title: getAEMValue('text_personal-guarantor-heading', parsedModel, 'text'),
      fields: [
        {
          title: getAEMValue('radiogroup_isPGProvided', parsedModel, 'label'),
          value: typeof isPGProvided === 'string' ? isPGProvided : null,
          isOptional: false
        }
      ]
    };
  }

  function getPG(personalGuarantors: Array<any>): Array<any> {
    if (isPGProvided && region === 'us') {
      return personalGuarantors.map((pg, index) => ({
        title: getPGHeading(index),
        fields: [
          {
            title: getAEMValue('textinput_pgFirstName', parsedModel, 'label'),
            value: typeof pg.firstName === 'string' ? pg.firstName : null,
            isOptional: false
          },
          {
            title: getAEMValue('textinput_pgLastName', parsedModel, 'label'),
            value: typeof pg.lastName === 'string' ? pg.lastName : null,
            isOptional: false
          },
          {
            title: getAEMValue('textinput_pgPhone', parsedModel, 'label'),
            value: typeof pg.phoneNum === 'string' ? pg.phoneNum : null,
            isOptional: false
          },
          {
            title: getAEMValue('textinput_pgEmail', parsedModel, 'label'),
            value: typeof pg.email === 'string' ? pg.email : null,
            isOptional: false
          }
        ]
      }));
    }
    return [];
  }

  function getPrimaryContactFields() {
    return [
      {
        title: getAEMValue('textinput_firstName', parsedModel, 'label'),
        value: typeof firstName === 'string' ? firstName : null,
        isOptional: false
      },
      {
        title: getAEMValue('textinput_lastName', parsedModel, 'label'),
        value: typeof lastName === 'string' ? lastName : null,
        isOptional: false
      },
      {
        title: getAEMValue('textinput_title', parsedModel, 'label'),
        value: typeof title === 'string' ? title : null,
        isOptional: false
      },
      {
        title: getAEMValue('textinput_phoneNum', parsedModel, 'label'),
        value: typeof phoneNum === 'string' ? phoneNum : null,
        isOptional: false
      },
      {
        title: getAEMValue('textinput_email', parsedModel, 'label'),
        value: typeof email === 'string' ? email : null,
        isOptional: false
      }
    ];
  }

  function getSecondaryContactSection() {
    const secondaryContactFields = [secondaryFirstName, secondaryLastName, secondaryTitle, secondaryPhoneNum, secondaryEmail];
    const hasSecondaryContact = secondaryContactFields.some((field) => typeof field === 'string' && field.trim() !== '');

    if (hasSecondaryContact) {
      return {
        title: getAEMValue('text_secondary-contact-heading', parsedModel, 'text'),
        fields: [
          {
            title: getAEMValue('textinput_secondaryFirstName', parsedModel, 'label'),
            value: typeof secondaryFirstName === 'string' ? secondaryFirstName : null,
            isOptional: true
          },
          {
            title: getAEMValue('textinput_secondaryLastName', parsedModel, 'label'),
            value: typeof secondaryLastName === 'string' ? secondaryLastName : null,
            isOptional: true
          },
          {
            title: getAEMValue('textinput_secondaryTitle', parsedModel, 'label'),
            value: typeof secondaryTitle === 'string' ? secondaryTitle : null,
            isOptional: true
          },
          {
            title: getAEMValue('textinput_secondaryPhoneNum', parsedModel, 'label'),
            value: typeof secondaryPhoneNum === 'string' ? secondaryPhoneNum : null,
            isOptional: true
          },
          {
            title: getAEMValue('textinput_secondaryEmail', parsedModel, 'label'),
            value: typeof secondaryEmail === 'string' ? secondaryEmail : null,
            isOptional: true
          }
        ]
      };
    }
    return null;
  }

  function createTransformedObject() {
    const transformedObj = {
      title: getAEMValue('text_form-heading', parsedModel, 'text'),
      isValid: Boolean(contactInformation.isValid),
      subSections: [
        {
          title: getAEMValue('text_primary-contact-heading', parsedModel, 'text'),
          fields: getPrimaryContactFields()
        },
        ...getBusinessOwners((businessOwners as Array<any>) ?? []),
        ...(totalOwnership
          ? []
          : [
              {
                title: (
                  <InlineAlert
                    name="total-ownership-error-alert"
                    titleOnly
                  />
                ),
                fields: []
              }
            ])
      ]
    };

    if (region == 'us') {
      transformedObj.subSections.push(getPersonalGuarantors());
      transformedObj.subSections.push(...getPG((personalGuarantors as Array<any>) ?? []));

      const secondaryContactSection = getSecondaryContactSection();
      if (secondaryContactSection) {
        transformedObj.subSections.splice(1, 0, secondaryContactSection);
      }
    }

    return transformedObj;
  }

  const transformedObj = createTransformedObject();

  return (
    <div data-testid="contact-info-details-card-test">
      <ReviewDetailsCard
        section={transformedObj}
        modifyHandler={() => updateReviewStep(2)}
      />
    </div>
  );
};

export default ReviewContactInfo;
