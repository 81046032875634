import { americanStates, canadianProvinces } from '../../../../apis/Regions';
import useClocStore from '../../../../store';
import { IClocState } from '../../../../store/types';
import { useModel } from '../../../../utils/aem/aemHOC';
import { getAEMPath, getAEMValue, getDateFromObj } from '../../../../utils/jsUtils';
import InlineAlert from '../../../atoms/inlineAlert';
import ReviewDetailsCard from '../../../molecules/reviewDetailsCard/reviewDetailsCard';

const ReviewBusinessInfo = () => {
  const validationStatus = useClocStore((state) => (state as IClocState).validationStatus.businessInformation);
  const businessInfo = useClocStore((state) => (state as IClocState).clocFormData.businessInformation);
  const region = useClocStore((state) => (state as IClocState).region);
  const updateReviewStep = useClocStore((state) => (state as IClocState).updateReviewStep);

  const parsedModel = useModel({ pagePath: getAEMPath('businessInformation') }).parsedModel;

  const {
    businessName,
    federalTaxId,
    gstHstRegistration,
    incorporationDate,
    doingBusinessAs,
    organizationState,
    incorporationProvince,
    website,
    street,
    city,
    state,
    province,
    zip,
    postalCode,
    garageStreet,
    garageState,
    garageCity,
    garageZip,
    garagePostalCode,
    garageProvince
  } = validationStatus;

  function getAddress(street: string, city: string, state: string, zip: string) {
    if (!(state && city && street && zip)) {
      return null;
    }
    return `${street}  \n${city}, ${state} ${zip}`;
  }

  const primaryLegalAddress = getAddress(
    street as string,
    city as string,
    region === 'us' ? (state as string) : (province as string),
    region === 'us' ? (zip as string) : (postalCode as string)
  );

  const vehicleGarageAddress = getAddress(
    garageStreet as string,
    garageCity as string,
    region === 'us' ? (garageState as string) : (garageProvince as string),
    region === 'us' ? (garageZip as string) : (garagePostalCode as string)
  );

  const getFederalTaxOrGstRegistration = () => {
    if (region === 'us') {
      return typeof federalTaxId === 'string' ? federalTaxId : null;
    } else {
      return typeof gstHstRegistration === 'string' ? gstHstRegistration : null;
    }
  };

  const getOrganizationState = () => {
    if (region === 'us') {
      return typeof organizationState === 'string' ? americanStates.find((state: any) => state.value === organizationState)?.label : null;
    } else {
      return typeof incorporationProvince === 'string'
        ? canadianProvinces.find((province: any) => province.value === incorporationProvince)?.label
        : null;
    }
  };

  const transformedObj = {
    title: getAEMValue('text_bi', parsedModel, 'text'),
    isValid: Boolean(businessInfo.isValid),
    subSections: [
      {
        title: '',
        fields: [
          {
            title: getAEMValue('textinput_businessName', parsedModel, 'label'),
            value: typeof businessName === 'string' ? businessName : null,
            isOptional: false
          },
          {
            title:
              region === 'us'
                ? getAEMValue('textinput_FederalTaxId', parsedModel, 'label')
                : getAEMValue('textinput_gstHstRegistration', parsedModel, 'label'),
            value: getFederalTaxOrGstRegistration(),
            isOptional: region === 'us' ? false : Boolean(businessInfo.noBusinessNumber)
          },
          ...(region === 'ca'
            ? [
                {
                  title: getAEMValue('datepicker_incorporationDate', parsedModel, 'inputLabel'),
                  value: getDateFromObj(incorporationDate),
                  isOptional: true
                }
              ]
            : []),
          {
            title:
              region === 'us'
                ? getAEMValue('singleselectinput_organizationState', parsedModel, 'label')
                : getAEMValue('singleselectinput_incorporationProvince', parsedModel, 'label'),
            value: getOrganizationState(),
            isOptional: false
          },
          {
            title: getAEMValue('textinput_doingBusinessAs', parsedModel, 'label'),
            value: typeof doingBusinessAs === 'string' ? doingBusinessAs : null,
            isOptional: true
          },
          {
            title: getAEMValue('text_legalAddress', parsedModel, 'text'),
            value: primaryLegalAddress,
            isOptional: false
          },
          {
            title: getAEMValue('text_vehicleGaragingAddress', parsedModel, 'text'),
            value: vehicleGarageAddress,
            isOptional: false
          },
          {
            title: getAEMValue('textinput_website', parsedModel, 'label'),
            value: typeof website === 'string' ? website : null,
            isOptional: true
          }
        ]
      },
      ...((typeof doingBusinessAs === 'string' && doingBusinessAs.length > 1) || typeof doingBusinessAs === 'undefined' || region === 'ca'
        ? []
        : [
            {
              title: (
                <InlineAlert
                  name="doingBusinessAs-error-alert"
                  titleOnly
                />
              ),
              fields: []
            }
          ])
    ]
  };

  return (
    <div data-testid="businessInfo-details-card-test">
      <ReviewDetailsCard
        section={transformedObj}
        modifyHandler={() => updateReviewStep(1)}
      />
    </div>
  );
};

export default ReviewBusinessInfo;
